import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Card, Typography, Tooltip, Grid, IconButton } from "@material-ui/core"
import GroupAddIcon from "@mui/icons-material/GroupAdd"
import {
    getADPPositionsDelta,
    createPosition,
    getPositions
} from "../../../actions/positionActions"

function PendingPosition(props) {
    const { department, jobTitle, classification, ppRoleID } = props
    const dispatch = useDispatch()

    // Store Variables
    const user = useSelector((state) => state.auth.user.email)
    const userPermissions = useSelector((state) => state.auth.permissions)

    // Create Position Handlers
    const handleClickCreatePosition = async () => {
        if (ppRoleID !== "none") {
            const newPosition = {
                RoleID: ppRoleID,
                Department: department,
                JobTitle: jobTitle,
                Classification: classification,
                AuditLog: {
                    Module: "Access Management",
                    Action: "Create Position (From Pending)",
                    Object: `Role ID: ${ppRoleID} | Department: ${department} | JobTitle: ${jobTitle} | Classification: ${classification}`,
                    ActionBy: user
                }
            }
            await dispatch(createPosition(newPosition))
            await dispatch(getADPPositionsDelta())
            await dispatch(getPositions())
        }
    }

    return (
        <Card>
            <Grid container>
                {/* Grid Left Side */}
                <Grid item xs={10} md={8}>
                    <Typography
                        variant="subtitle1"
                        style={{
                            marginTop: "9px",
                            marginLeft: "14px"
                        }}
                    >
                        {`${department} | ${jobTitle} | ${classification}`}
                    </Typography>
                </Grid>
                {/* Grid Right Side */}
                <Grid item align="right" xs={2} md={4}>
                    {userPermissions.EditAccessManagement && (
                        <Tooltip title="Assign Position to Role">
                            <IconButton onClick={handleClickCreatePosition}>
                                <GroupAddIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </Grid>
            </Grid>
        </Card>
    )
}

export default PendingPosition
