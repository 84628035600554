import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
    Card,
    Typography,
    IconButton,
    Tooltip,
    Grid,
    Collapse,
    CardContent,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select
} from "@material-ui/core"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { ExpandMore } from "../../main/ExpandMore"
import {
    updateSecurityArea,
    getSecurityAreas,
    deleteSecurityArea
} from "../../../actions/securityAreaActions"

function SecurityArea(props) {
    const {
        securityAreaID,
        securityAreaName,
        securityAreaOwnerAOID,
        securityAreaOwner
    } = props
    const dispatch = useDispatch()

    // Store Variables
    const employees = useSelector((state) => state.employees.activeEmployees)
    const roles = useSelector((state) => state.roles.roles)
    const user = useSelector((state) => state.auth.user.email)
    const userPermissions = useSelector((state) => state.auth.permissions)

    // State Variables
    const [expanded, setExpanded] = useState(false)

    // Edit Dialog State
    const [editDialogOpen, setEditDialogOpen] = useState(false)
    const [editDialogValues, setEditDialogValues] = useState({
        securityAreaID: "",
        securityAreaName: "",
        securityAreaOwnerAOID: ""
    })

    // Delete Dialog State
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [deleteDialogValues, setDeleteDialogValues] = useState({
        securityAreaID: "",
        securityAreaName: "",
        confirmDelete: ""
    })

    // Handlers
    const handleClickExpand = () => {
        setExpanded(!expanded)
    }

    // Edit Dialog Handlers
    const handleClickEdit = () => {
        setEditDialogValues({
            securityAreaID,
            securityAreaName,
            securityAreaOwnerAOID
        })
        setEditDialogOpen(true)
    }

    const handleCloseEditDialog = () => {
        setEditDialogOpen(false)
        clearEditDialogValues()
    }

    const handleChangeEditDialog = (e) => {
        const name = e.target.name
        const value = e.target.value

        setEditDialogValues({
            ...editDialogValues,
            [name]: value
        })
    }

    const clearEditDialogValues = () => {
        setEditDialogValues({
            securityAreaID: "",
            securityAreaName: "",
            securityAreaOwnerAOID: ""
        })
    }

    const handleUpdateSecurityArea = async () => {
        const updatedSecurityArea = {
            SecurityAreaID: editDialogValues.securityAreaID,
            SecurityAreaName: editDialogValues.securityAreaName,
            SecurityAreaOwnerAOID: editDialogValues.securityAreaOwnerAOID,
            AuditLog: {
                Module: "Access Management",
                Action: "Update Security Area",
                Object: `Security Area ID: ${editDialogValues.securityAreaID} | Security Area Name: ${editDialogValues.securityAreaName} | Security Area Owner AOID: ${editDialogValues.securityAreaOwnerAOID}`,
                ActionBy: user
            }
        }
        await dispatch(updateSecurityArea(updatedSecurityArea))
        await dispatch(getSecurityAreas())
        handleCloseEditDialog()
        clearEditDialogValues()
    }

    // Delete Dialog Handlers
    const handleClickDelete = () => {
        setDeleteDialogValues({
            securityAreaID,
            securityAreaName,
            confirmDelete: ""
        })
        setDeleteDialogOpen(true)
    }

    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false)
        clearDeleteDialogValues()
    }

    const clearDeleteDialogValues = () => {
        setDeleteDialogValues({
            securityAreaID: "",
            securityAreaName: "",
            confirmDelete: ""
        })
    }

    const handleDeleteSecurityArea = async () => {
        if (
            deleteDialogValues.confirmDelete !== "" &&
            deleteDialogValues.confirmDelete ===
                deleteDialogValues.securityAreaName
        ) {
            const securityAreaToDelete = {
                SecurityAreaID: deleteDialogValues.securityAreaID,
                AuditLog: {
                    Module: "Access Management",
                    Action: "Delete Security Area",
                    Object: `Security Area ID: ${securityAreaID} | Security Area Name: ${securityAreaName}`,
                    ActionBy: user
                }
            }
            await dispatch(deleteSecurityArea(securityAreaToDelete))
            await dispatch(getSecurityAreas())
            handleCloseDeleteDialog()
            clearDeleteDialogValues()
        }
    }

    const handleChangeDeleteDialog = (e) => {
        const name = e.target.name
        const value = e.target.value

        setDeleteDialogValues({
            ...deleteDialogValues,
            [name]: value
        })
    }

    return (
        <Card>
            <Grid container>
                {/* Grid Left Side */}
                <Grid item xs={6}>
                    <Typography
                        variant="subtitle1"
                        style={{
                            marginTop: "9px",
                            marginLeft: "14px"
                        }}
                    >
                        {securityAreaName}
                    </Typography>
                </Grid>
                {/* Grid Right Side */}
                <Grid item align="right" xs={6}>
                    {userPermissions.EditAccessManagement && (
                        <Tooltip title="Edit Security Area">
                            <IconButton onClick={handleClickEdit}>
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    {userPermissions.EditAccessManagement && (
                        <Tooltip title="Delete Security Area">
                            <IconButton onClick={handleClickDelete}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    <ExpandMore
                        expand={expanded}
                        onClick={handleClickExpand}
                        aria-expanded={expanded}
                    >
                        <Tooltip title={!expanded ? "Show More" : "Show Less"}>
                            <ExpandMoreIcon />
                        </Tooltip>
                    </ExpandMore>
                </Grid>
            </Grid>

            {/* Expand More Content */}
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Typography variant="subtitle2">{`Owner: ${securityAreaOwner}`}</Typography>
                    <br />
                    <Typography variant="subtitle2">Included Roles:</Typography>
                    {roles
                        .filter(
                            (role) => role.SecurityAreaID === securityAreaID
                        )
                        .map((role) => (
                            <Typography variant="body2">
                                {role.RoleName}
                            </Typography>
                        ))}
                </CardContent>
            </Collapse>

            {/* Edit Dialog */}
            <Dialog
                open={editDialogOpen}
                onClose={handleCloseEditDialog}
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle>Edit Security Area</DialogTitle>
                <DialogContent>
                    {/* Security Area Name Field */}
                    <TextField
                        autoFocus
                        margin="dense"
                        id="securityAreaName"
                        label="Security Area Name"
                        type="text"
                        name="securityAreaName"
                        value={editDialogValues.securityAreaName}
                        onChange={handleChangeEditDialog}
                        fullWidth
                    />
                    {/* Security Owner Field */}
                    <FormControl fullWidth={true} style={{ marginTop: "20px" }}>
                        <InputLabel htmlFor="securityAreaOwnerAOID">
                            Security Area Owner
                        </InputLabel>
                        <Select
                            native
                            value={editDialogValues.securityAreaOwnerAOID}
                            onChange={handleChangeEditDialog}
                            label="Security Area Owner"
                            inputProps={{
                                name: "securityAreaOwnerAOID",
                                id: "securityAreaOwnerAOID"
                            }}
                        >
                            <option key="none" value="none">
                                Select an Owner
                            </option>
                            {employees.map((employee) => (
                                <option
                                    key={employee.AssociateOID}
                                    value={employee.AssociateOID}
                                >
                                    {`${employee.FirstName} ${employee.LastName}`}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleUpdateSecurityArea}
                        color="secondary"
                    >
                        Update
                    </Button>
                    <Button onClick={handleCloseEditDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete Dialog */}
            <Dialog
                open={deleteDialogOpen}
                onClose={handleCloseDeleteDialog}
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle>Delete Security Area</DialogTitle>
                <DialogContent>
                    <strong>WARNING: This action cannot be reversed!</strong>
                    <br /> Are you sure you want to delete this security area?
                    {/* Confirm Delete Field */}
                    <TextField
                        margin="dense"
                        id="confirmDelete"
                        label="Enter security area name to confirm"
                        type="text"
                        name="confirmDelete"
                        value={deleteDialogValues.confirmDelete}
                        onChange={handleChangeDeleteDialog}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleDeleteSecurityArea}
                        color="secondary"
                    >
                        Delete
                    </Button>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Card>
    )
}

export default SecurityArea
