import { useEffect } from "react"
import { HashRouter as Router, Route, Routes } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { CssBaseline } from "@material-ui/core"
import { createTheme, responsiveFontSizes } from "@material-ui/core/styles"
import { ThemeProvider } from "@material-ui/styles"
import Navigation from "./Navigation"
import LoginPage from "../login/LoginPage"
import UserNotFound from "../login/UserNotFound"
import HomePage from "../home/HomePage"
import Footer from "./Footer"
import RolesPage from "../accessManagement/roles/RolesPage"
import PermissionsPage from "../accessManagement/permissions/PermissionsPage"
import "../../fonts/fonts.css"
import { useAuth } from "../../functions/useAuth"
import resetHeaders from "../../functions/resetHeaders"

function App() {
    const dispatch = useDispatch()
    useAuth(dispatch)

    const loggedIn = useSelector(
        (state) => state.auth.user && state.auth.user.auth
    )
    const userData = useSelector((state) => state.auth.userData)

    useEffect(() => {
        if (loggedIn) {
            resetHeaders()
        }
        return () => {}
    }, [loggedIn])

    let palette = {
        primary: { main: "#717073" },
        secondary: { main: "#DF7A00" }
    }

    if (userData) {
        palette = { ...palette, type: userData.DarkMode }
    }

    const theme = createTheme({
        palette,
        typography: {
            fontFamily: ["Century !important"].join(",")
        }
    })

    const ComponentPage = (page) => {
        return (
            <>
                <div style={{ height: "10vh" }}>
                    <Navigation />
                </div>
                <div style={{ minHeight: "86.7vh" }}>{page}</div>
                <div style={{ height: "3vh" }}>
                    <Footer />
                </div>
            </>
        )
    }

    return (
        <ThemeProvider theme={responsiveFontSizes(theme)}>
            <Router>
                <CssBaseline />

                <Routes>
                    <Route path="/" exact element={<LoginPage />} />
                    <Route
                        path="/user-not-found"
                        exact
                        element={ComponentPage(<UserNotFound />)}
                    />
                    <Route
                        path="/home"
                        exact
                        element={ComponentPage(<HomePage />)}
                    />
                    <Route
                        path="/roles"
                        exact
                        element={ComponentPage(<RolesPage />)}
                    />
                    <Route
                        path="/permissions"
                        exact
                        element={ComponentPage(<PermissionsPage />)}
                    />
                </Routes>
            </Router>
        </ThemeProvider>
    )
}

export default App
