import { GET_ACTIVE_EMPLOYEES } from "../actions/types"

const initialState = {
    activeEmployees: []
}

const employeeReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ACTIVE_EMPLOYEES:
            return { ...state, activeEmployees: action.payload }

        default:
            return state
    }
}

export default employeeReducer
