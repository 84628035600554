import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Grid } from "@material-ui/core"
import LoginPrompt from "../../login/LoginPrompt"
import RolesPageSide from "./RolesPageSide"
import RolesPageMain from "./RolesPageMain"
import { getRoles } from "../../../actions/roleActions"
import { getSecurityAreas } from "../../../actions/securityAreaActions"
import {
    getADPPositionsDelta,
    getPositions
} from "../../../actions/positionActions"
import { getPermissions } from "../../../actions/permissionActions"
import { getRolePermissions } from "../../../actions/rolePermissionActions"
import { getActiveEmployees } from "../../../actions/employeeActions"
import { getExceptions } from "../../../actions/exceptionActions"

function RolesPage() {
    const dispatch = useDispatch()

    // Store Variables
    const loggedIn = useSelector(
        (state) => state.auth.user && state.auth.user.auth
    )
    const authenticated = useSelector((state) => state.auth.authenticated)

    useEffect(() => {
        if (loggedIn && authenticated) {
            async function runUseEffectFunctions() {
                await dispatch(getRoles())
                await dispatch(getSecurityAreas())
                await dispatch(getPositions())
                await dispatch(getPermissions())
                await dispatch(getRolePermissions())
                await dispatch(getActiveEmployees())
                await dispatch(getADPPositionsDelta())
                await dispatch(getExceptions())
            }
            runUseEffectFunctions()
        }
        return () => {}
    }, [dispatch, loggedIn, authenticated])

    return (
        <>
            {authenticated ? (
                <Grid container justifyContent="center">
                    <Grid item xs={12} md={3}>
                        <RolesPageSide />
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <RolesPageMain />
                    </Grid>
                </Grid>
            ) : (
                <LoginPrompt />
            )}
        </>
    )
}

export default RolesPage
