import {
    GET_SECURITY_AREAS,
    CREATE_SECURITY_AREA,
    UPDATE_SECURITY_AREA,
    DELETE_SECURITY_AREA
} from "../actions/types"

const initialState = {
    securityAreas: []
}

const securityAreaReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SECURITY_AREAS:
            return {
                ...state,
                securityAreas: action.payload
            }
        case CREATE_SECURITY_AREA:
            return {
                ...state
            }
        case UPDATE_SECURITY_AREA:
            return {
                ...state
            }
        case DELETE_SECURITY_AREA:
            return {
                ...state
            }
        default:
            return state
    }
}

export default securityAreaReducer
