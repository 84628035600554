import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { IconButton, Grid, Typography, Tooltip } from "@material-ui/core"
import DeleteIcon from "@mui/icons-material/Delete"
import CloseIcon from "@mui/icons-material/Close"
import CheckIcon from "@mui/icons-material/Check"
import {
    deleteException,
    getExceptions
} from "../../../actions/exceptionActions"

function Exception(props) {
    const {
        exceptionID,
        associateOID,
        permissionID,
        exceptionReason,
        fullName,
        permission
    } = props
    const dispatch = useDispatch()

    // Store Variables
    const user = useSelector((state) => state.auth.user.email)
    const userPermissions = useSelector((state) => state.auth.permissions)

    // Delete Exception State
    const [selectedForDelete, setSelectedForDelete] = useState(false)

    // Delete Exception Handlers
    const handleClickDeleteException = () => {
        setSelectedForDelete(true)
    }

    const handleClickCancelDeleteException = () => {
        setSelectedForDelete(false)
    }

    const handleClickConfirmDeleteException = async () => {
        const exceptionToDelete = {
            ExceptionID: exceptionID,
            AuditLog: {
                Module: "Access Management",
                Action: "Delete Exception",
                Object: `Exception ID: ${exceptionID} | AssociateOID: ${associateOID} | Permission ID: ${permissionID}`,
                ActionBy: user
            }
        }
        await dispatch(deleteException(exceptionToDelete))
        await dispatch(getExceptions())
    }

    return (
        <Grid container>
            <Grid item xs={9}>
                <Typography
                    variant="subtitle1"
                    style={{
                        marginTop: "9px"
                    }}
                >
                    {`${fullName} - ${permission}`}
                </Typography>
            </Grid>
            {userPermissions.EditAccessManagement && !selectedForDelete && (
                <Tooltip title="Delete Exception">
                    <Grid item xs={1}>
                        <IconButton onClick={handleClickDeleteException}>
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                </Tooltip>
            )}
            {selectedForDelete && (
                <Grid item xs={1}>
                    <Tooltip title="Cancel">
                        <IconButton onClick={handleClickCancelDeleteException}>
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            )}
            {selectedForDelete && (
                <Grid item xs={1}>
                    <Tooltip title="Confirm">
                        <IconButton onClick={handleClickConfirmDeleteException}>
                            <CheckIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            )}
            <Grid item xs={12}>
                <Typography variant="subtitle1">
                    {`Reason: ${exceptionReason} `}
                </Typography>
            </Grid>
        </Grid>
    )
}

export default Exception
