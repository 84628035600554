import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Grid } from "@material-ui/core"
import LoginPrompt from "../login/LoginPrompt"
import AccessManagementLinks from "./AccessManagementLinks"

function HomePage() {
    const dispatch = useDispatch()

    // Store Variables
    const loggedIn = useSelector(
        (state) => state.auth.user && state.auth.user.auth
    )
    const authenticated = useSelector((state) => state.auth.authenticated)
    const userPermissions = useSelector((state) => state.auth.permissions)

    useEffect(() => {
        if (loggedIn) {
            async function runUseEffectFunctions() {
                //await dispatch()
            }
            runUseEffectFunctions()
        }
        return () => {}
    }, [dispatch, loggedIn])

    return (
        <>
            {authenticated ? (
                <Grid container justify="center">
                    {userPermissions.ViewAccessManagement && (
                        <AccessManagementLinks />
                    )}
                </Grid>
            ) : (
                <LoginPrompt />
            )}
        </>
    )
}

export default HomePage
