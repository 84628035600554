import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
    Typography,
    Grid,
    TextField,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Switch
} from "@material-ui/core"
import Stack from "@mui/material/Stack"
import Skeleton from "@mui/material/Skeleton"
import Autocomplete from "@mui/material/Autocomplete"
import Permission from "../permissions/Permission"
import Pagination from "@mui/material/Pagination"
import {
    createPermission,
    getPermissions
} from "../../../actions/permissionActions"

function PermissionsPageMain() {
    const dispatch = useDispatch()

    // Store Variables
    const permissions = useSelector((state) => state.permissions.permissions)
    const user = useSelector((state) => state.auth.user.email)
    const userPermissions = useSelector((state) => state.auth.permissions)
    const userData = useSelector((state) => state.auth.userData)

    // Constants
    const arrayOfFifteen = Array.from({ length: 15 }, (_, index) => index + 1)

    // Autocomplete Arrays
    const categories = [
        ...new Set(permissions.map((permission) => permission.Category))
    ]
    const applications = [
        ...new Set(permissions.map((permission) => permission.Application))
    ]
    const modules = [
        ...new Set(permissions.map((permission) => permission.Module))
    ]
    const permissionLevels = [
        ...new Set(permissions.map((permission) => permission.PermissionLevel))
    ]

    // Filter State
    const [filterValues, setFilterValues] = useState({
        categoryFilter: "",
        applicationFilter: "",
        moduleFilter: ""
    })

    // Page State
    const [page, setPage] = useState(1)

    // Create Dialog State
    const [createDialogOpen, setCreateDialogOpen] = useState(false)
    const [createDialogValues, setCreateDialogValues] = useState({
        category: "",
        application: "",
        module: "",
        permissionLevel: ""
    })

    const [costAssociatedValue, setCostAssociatedValue] = useState(false)

    // Filter Handlers
    const handleChangeFilterValues = (e) => {
        const name = e.target.name
        const value = e.target.value

        setFilterValues({
            ...filterValues,
            [name]: value
        })

        setPage(1)
    }

    // Page Handlers
    const handleChangePage = (event, value) => {
        setPage(value)
    }

    // Create Dialog Handlers
    const handleClickCreate = () => {
        clearCreateDialogValues()
        setCreateDialogOpen(true)
    }

    const handleCloseCreateDialog = () => {
        setCreateDialogOpen(false)
        clearCreateDialogValues()
    }

    const clearCreateDialogValues = () => {
        setCreateDialogValues({
            category: "",
            application: "",
            module: "",
            permissionLevel: ""
        })
        setCostAssociatedValue(false)
    }

    const handleInputChangeCategory = (event, newValue) => {
        setCreateDialogValues((prevValues) => ({
            ...prevValues,
            category: newValue
        }))
    }

    const handleInputChangeApplication = (event, newValue) => {
        setCreateDialogValues((prevValues) => ({
            ...prevValues,
            application: newValue
        }))
    }

    const handleInputChangeModule = (event, newValue) => {
        setCreateDialogValues((prevValues) => ({
            ...prevValues,
            module: newValue
        }))
    }
    const handleInputChangePermissionLevel = (event, newValue) => {
        setCreateDialogValues((prevValues) => ({
            ...prevValues,
            permissionLevel: newValue
        }))
    }

    const handleChangeCostAssociated = (e) => {
        setCostAssociatedValue(e.target.checked)
    }

    const handleCreate = async () => {
        if (
            createDialogValues.category !== "" &&
            createDialogValues.application !== "" &&
            createDialogValues.module !== "" &&
            createDialogValues.permissionLevel !== "" &&
            existingPermissions.length === 0
        ) {
            const newPermission = {
                Category: createDialogValues.category,
                Application: createDialogValues.application,
                Module: createDialogValues.module,
                PermissionLevel: createDialogValues.permissionLevel,
                CostAssociated: costAssociatedValue,
                AuditLog: {
                    Module: "Access Management",
                    Action: "Create Permission",
                    Object: `Catgory: ${createDialogValues.category} | Application: ${createDialogValues.application} | Module: ${createDialogValues.module} | Permission Level: ${createDialogValues.permissionLevel} | Cost Associated: ${costAssociatedValue}`,
                    ActionBy: user
                }
            }
            await dispatch(createPermission(newPermission))
            await dispatch(getPermissions())
            handleCloseCreateDialog()
            clearCreateDialogValues()
        }
    }

    // Filtering Positions Logic
    let filteredPermissions = []
    if (permissions && permissions.length > 0) {
        filteredPermissions = permissions.filter((permission) => {
            const categoryFilter = filterValues.categoryFilter.toLowerCase()
            const applicationFilter =
                filterValues.applicationFilter.toLowerCase()
            const moduleFilter = filterValues.moduleFilter.toLowerCase()

            const categoryMatch =
                permission.Category &&
                permission.Category.toLowerCase().includes(categoryFilter)
            const applicationMatch =
                permission.Application &&
                permission.Application.toLowerCase().includes(applicationFilter)
            const moduleMatch =
                permission.Module &&
                permission.Module.toLowerCase().includes(moduleFilter)

            return categoryMatch && applicationMatch && moduleMatch
        })
    } else {
        filteredPermissions = permissions
    }

    // Existing Permissions Logic
    let existingPermissions = []
    if (
        permissions &&
        permissions.length > 0 &&
        (createDialogValues.category !== "" ||
            createDialogValues.application !== "" ||
            createDialogValues.module !== "" ||
            createDialogValues.permissionLevel !== "")
    ) {
        existingPermissions = permissions.filter((permission) => {
            const categoryFilter = createDialogValues.category.toLowerCase()
            const applicationFilter =
                createDialogValues.application.toLowerCase()
            const moduleFilter = createDialogValues.module.toLowerCase()
            const permissionLevelFilter =
                createDialogValues.permissionLevel.toLowerCase()

            const categoryMatch =
                permission.Category &&
                permission.Category.toLowerCase().includes(categoryFilter)
            const applicationMatch =
                permission.Application &&
                permission.Application.toLowerCase().includes(applicationFilter)
            const moduleMatch =
                permission.Module &&
                permission.Module.toLowerCase().includes(moduleFilter)
            const permissionLevelMatch =
                permission.PermissionLevel &&
                permission.PermissionLevel.toLowerCase().includes(
                    permissionLevelFilter
                )

            return (
                categoryMatch &&
                applicationMatch &&
                moduleMatch &&
                permissionLevelMatch
            )
        })
    } else {
        existingPermissions = []
    }

    // Pagination Logic
    const itemsPerPage = 10
    const startIndex = (page - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage
    const paginatedPermissions = filteredPermissions.slice(startIndex, endIndex)

    return (
        <Grid container justify="center">
            <Stack spacing={1} style={{ width: "80%", marginBottom: "20px" }}>
                <Typography variant="h5">Permissions</Typography>
                {/* Filters */}
                <Grid container style={{ marginBottom: "20px" }}>
                    <Grid
                        item
                        xs={12}
                        md={3}
                        style={{
                            paddingLeft: "25px",
                            paddingRight: "25px"
                        }}
                    >
                        {/* Category Filter */}
                        <TextField
                            margin="dense"
                            id="categoryFilter"
                            label="Category Filter"
                            type="text"
                            name="categoryFilter"
                            value={filterValues.categoryFilter}
                            onChange={handleChangeFilterValues}
                            fullWidth
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={3}
                        style={{
                            paddingLeft: "25px",
                            paddingRight: "25px"
                        }}
                    >
                        {/* Application Filter */}
                        <TextField
                            margin="dense"
                            id="applicationFilter"
                            label="Application Filter"
                            type="text"
                            name="applicationFilter"
                            value={filterValues.applicationFilter}
                            onChange={handleChangeFilterValues}
                            fullWidth
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={3}
                        style={{
                            paddingLeft: "25px",
                            paddingRight: "25px"
                        }}
                    >
                        {/* Module Filter */}
                        <TextField
                            margin="dense"
                            id="moduleFilter"
                            label="Module Filter"
                            type="text"
                            name="moduleFilter"
                            value={filterValues.moduleFilter}
                            onChange={handleChangeFilterValues}
                            fullWidth
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={3}
                        align="center"
                        style={{
                            paddingTop: "15px"
                        }}
                    >
                        {/* Create Permission Button */}
                        {userPermissions.EditAccessManagement && (
                            <Button
                                variant="contained"
                                onClick={handleClickCreate}
                            >
                                Create Permission
                            </Button>
                        )}
                    </Grid>
                </Grid>
                {/* Page */}
                <Stack spacing={1}>
                    <Typography>Page: {page}</Typography>
                    <Pagination
                        count={Math.ceil(
                            filteredPermissions?.length / itemsPerPage
                        )}
                        page={page}
                        onChange={handleChangePage}
                        sx={{
                            "& .MuiPaginationItem-root": {
                                color:
                                    userData.DarkMode === "dark"
                                        ? "#fff"
                                        : "#717073"
                            }
                        }}
                    />
                </Stack>
                {/* Permissions */}
                {paginatedPermissions?.length > 0
                    ? paginatedPermissions.map((permission) => (
                          <Permission
                              permissionID={permission.PermissionID}
                              category={permission.Category}
                              application={permission.Application}
                              module={permission.Module}
                              permissionLevel={permission.PermissionLevel}
                              documentationURL={permission.DocumentationURL}
                              exclusive={permission.Exclusive}
                              restriction={permission.Restriction}
                              costAssociated={permission.CostAssociated}
                          />
                      ))
                    : arrayOfFifteen.map((index) => (
                          <Skeleton
                              variant="rounded"
                              height={50}
                              animation="wave"
                          />
                      ))}
            </Stack>

            {/* Create Permission Dialog */}
            <Dialog
                open={createDialogOpen}
                onClose={handleCloseCreateDialog}
                fullWidth={true}
                maxWidth="lg"
                style={{ marginTop: "5vh" }}
            >
                <DialogTitle>Create Permission</DialogTitle>
                <DialogContent>
                    <Grid container spacing={1}>
                        {/* Category Autocomplete */}
                        <Grid item xs={12} md={3}>
                            <Autocomplete
                                name="category"
                                value={createDialogValues.category}
                                onInputChange={handleInputChangeCategory}
                                freeSolo
                                options={categories}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Category"
                                        variant="outlined"
                                    />
                                )}
                            />
                        </Grid>
                        {/* Application Autocomplete */}
                        <Grid item xs={12} md={3}>
                            <Autocomplete
                                name="application"
                                value={createDialogValues.application}
                                onInputChange={handleInputChangeApplication}
                                freeSolo
                                options={applications}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Application"
                                        variant="outlined"
                                    />
                                )}
                            />
                        </Grid>
                        {/* Module Autocomplete */}
                        <Grid item xs={12} md={3}>
                            <Autocomplete
                                name="module"
                                value={createDialogValues.module}
                                onInputChange={handleInputChangeModule}
                                freeSolo
                                options={modules}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Module"
                                        variant="outlined"
                                    />
                                )}
                            />
                        </Grid>
                        {/* Permission Level Autocomplete */}
                        <Grid item xs={12} md={3}>
                            <Autocomplete
                                name="permissionLevel"
                                value={createDialogValues.permissionLevel}
                                onInputChange={handleInputChangePermissionLevel}
                                freeSolo
                                options={permissionLevels}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Permission Level"
                                        variant="outlined"
                                    />
                                )}
                            />
                        </Grid>
                        {/* Cost Associated Field */}
                        <Grid item xs={12}>
                            Cost Associated
                            <Switch
                                checked={costAssociatedValue}
                                onChange={handleChangeCostAssociated}
                            />
                        </Grid>
                    </Grid>
                    <Typography
                        variant="subtitle1"
                        style={{ marginTop: "20px" }}
                    >
                        Existing Permissions:
                    </Typography>
                    {existingPermissions.map((permission) => (
                        <Typography variant="body2">{`${permission.Category} | ${permission.Application} | ${permission.Module} | ${permission.PermissionLevel}`}</Typography>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCreate} color="secondary">
                        Create
                    </Button>
                    <Button onClick={handleCloseCreateDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}

export default PermissionsPageMain
