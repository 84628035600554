import {
    CREATE_POSITION,
    DELETE_POSITION,
    GET_ADP_POSITIONS_DELTA,
    GET_POSITIONS,
    UPDATE_POSITION
} from "../actions/types"

const initialState = {
    positions: [],
    adpPositionsDelta: []
}

const positionReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_POSITIONS:
            return {
                ...state,
                positions: action.payload
            }
        case GET_ADP_POSITIONS_DELTA:
            return {
                ...state,
                adpPositionsDelta: action.payload
            }
        case CREATE_POSITION:
            return {
                ...state
            }
        case UPDATE_POSITION:
            return {
                ...state
            }
        case DELETE_POSITION:
            return {
                ...state
            }
        default:
            return state
    }
}

export default positionReducer
