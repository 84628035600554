import { makeStyles } from "@material-ui/core/styles"
import triangles from "../../../images/preciTriangles.svg"

const useStyles = makeStyles((theme) => ({
    LoginPage: {
        backgroundImage: `url(${triangles})`,
        height: "100vh"
    },
    LoginCard: {
        height: "300px",
        width: "350px",
        margin: "auto",
        backgroundColor: "#df7a00",
        opacity: 0.85
    },
    Logo: {
        height: "150px",
        marginTop: "10px",
        marginBottom: "20px"
    }
}))

export default useStyles
