import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
    Grid,
    Button,
    Card,
    CardContent,
    Typography,
    Badge,
    IconButton,
    Select,
    FormControl,
    MenuItem,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    TextField,
    InputLabel
} from "@material-ui/core"
import Stack from "@mui/material/Stack"
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople"
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff"
import SecurityArea from "./SecurityArea"
import Position from "./Position"
import PendingPosition from "./PendingPosition"
import Exception from "./Exception"
import {
    createRole,
    getRoles,
    updateRoleFilter
} from "../../../actions/roleActions"
import {
    createSecurityArea,
    getSecurityAreas
} from "../../../actions/securityAreaActions"
import { createPosition, getPositions } from "../../../actions/positionActions"
import {
    createException,
    getExceptions
} from "../../../actions/exceptionActions.js"

function RolesPageSide() {
    const dispatch = useDispatch()

    // Store Variables
    const securityAreas = useSelector(
        (state) => state.securityAreas.securityAreas
    )
    const employees = useSelector((state) => state.employees.activeEmployees)
    const adpPositionsDelta = useSelector(
        (state) => state.positions.adpPositionsDelta
    )
    const positions = useSelector((state) => state.positions.positions)
    const roles = useSelector((state) => state.roles.roles)
    const user = useSelector((state) => state.auth.user.email)
    const userPermissions = useSelector((state) => state.auth.permissions)
    const exceptions = useSelector((state) => state.exceptions.exceptions)
    const permissions = useSelector((state) => state.permissions.permissions)

    // State Variables
    const [roleFilter, setRoleFilter] = useState("none")

    // Manage Security Areas Dialog State
    const [securityAreasDialogOpen, setSecurityAreasDialogOpen] =
        useState(false)

    // Create Security Area Dialog State
    const [createSecurityAreaDialogOpen, setCreateSecurityAreaDialogOpen] =
        useState(false)
    const [createSecurityAreaDialogValues, setCreateSecurityAreaDialogValues] =
        useState({
            securityAreaName: "",
            securityAreaOwnerAOID: "none"
        })

    // Create Role Dialog State
    const [createRoleDialogOpen, setCreateRoleDialogOpen] = useState(false)
    const [createRoleDialogValues, setCreateRoleDialogValues] = useState({
        roleName: "",
        roleShortName: "",
        securityAreaID: "none"
    })

    // Manage Positions Dialog State
    const [positionsDialogOpen, setPositionsDialogOpen] = useState(false)
    const [positionsDialogValues, setPositionsDialogValues] = useState({
        departmentFilter: "",
        jobTitleFilter: ""
    })

    // Pending Positions Dialog State
    const [pendingPositionsDialogOpen, setPendingPositionsDialogOpen] =
        useState(false)
    const [pendingPositionsDialogValues, setPendingPositionsDialogValues] =
        useState({
            roleID: "none"
        })

    // Create Position Dialog State
    const [createPositionDialogOpen, setCreatePositionDialogOpen] =
        useState(false)
    const [createPositionDialogValues, setCreatePositionDialogValues] =
        useState({
            department: "",
            jobTitle: "",
            classification: "",
            roleID: ""
        })

    // Manage Exceptions Dialog State
    const [exceptionsDialogOpen, setExceptionsDialogOpen] = useState(false)

    // Create Exception Dialog State
    const [createExceptionDialogOpen, setCreateExceptionDialogOpen] =
        useState(false)
    const [createExceptionDialogValues, setCreateExceptionDialogValues] =
        useState({
            associateOID: "none",
            permissionID: "none",
            exceptionReason: ""
        })

    // Role Filter Handlers
    const handleChangeRoleFilter = (event) => {
        setRoleFilter(event.target.value)
        dispatch(updateRoleFilter(event.target.value))
    }

    const handleClickClearFilter = () => {
        setRoleFilter("none")
        dispatch(updateRoleFilter("none"))
    }

    // Manage Security Areas Dialog Handlers
    const handleClickManageSecurityAreas = () => {
        setSecurityAreasDialogOpen(true)
    }

    const handleCloseSecurityAreasDialog = () => {
        setSecurityAreasDialogOpen(false)
    }

    // Create Security Area Dialog Handlers
    const handleClickCreateSecurityArea = () => {
        clearCreateSecurityAreaDialogValues()
        setCreateSecurityAreaDialogOpen(true)
    }

    const handleCloseCreateSecurityAreaDialog = () => {
        setCreateSecurityAreaDialogOpen(false)
        clearCreateSecurityAreaDialogValues()
    }

    const handleChangeCreateSecurityAreaDialog = (e) => {
        const name = e.target.name
        const value = e.target.value

        setCreateSecurityAreaDialogValues({
            ...createSecurityAreaDialogValues,
            [name]: value
        })
    }

    const clearCreateSecurityAreaDialogValues = () => {
        setCreateSecurityAreaDialogValues({
            securityAreaName: "",
            securityAreaOwnerAOID: "none"
        })
    }

    const handleCreateSecurityArea = async () => {
        if (
            createSecurityAreaDialogValues.securityAreaName !== "" &&
            createSecurityAreaDialogValues.securityAreaOwnerAOID !== "none"
        ) {
            const newSecurityArea = {
                SecurityAreaName:
                    createSecurityAreaDialogValues.securityAreaName,
                SecurityAreaOwnerAOID:
                    createSecurityAreaDialogValues.securityAreaOwnerAOID,
                AuditLog: {
                    Module: "Access Management",
                    Action: "Create Security Area",
                    Object: `Security Area Name: ${createSecurityAreaDialogValues.securityAreaName} | Security Area Owner AOID: ${createSecurityAreaDialogValues.securityAreaOwnerAOID}`,
                    ActionBy: user
                }
            }
            await dispatch(createSecurityArea(newSecurityArea))
            await dispatch(getSecurityAreas())
            handleCloseCreateSecurityAreaDialog()
            clearCreateSecurityAreaDialogValues()
        }
    }

    // Create Role Dialog Handlers
    const handleClickCreateRole = () => {
        clearCreateRoleDialogValues()
        setCreateRoleDialogOpen(true)
    }

    const handleCloseCreateRoleDialog = () => {
        setCreateRoleDialogOpen(false)
        clearCreateRoleDialogValues()
    }

    const handleChangeCreateRoleDialog = (e) => {
        const name = e.target.name
        const value = e.target.value

        setCreateRoleDialogValues({
            ...createRoleDialogValues,
            [name]: value
        })
    }

    const clearCreateRoleDialogValues = () => {
        setCreateRoleDialogValues({
            roleName: "",
            roleShortName: "",
            securityAreaID: "none"
        })
    }

    const handleCreateRole = async () => {
        if (
            createRoleDialogValues.roleName !== "" &&
            createRoleDialogValues.roleShortName !== "" &&
            createRoleDialogValues.securityAreaID !== "none"
        ) {
            const newRole = {
                RoleName: createRoleDialogValues.roleName,
                RoleShortName: createRoleDialogValues.roleShortName,
                SecurityAreaID: createRoleDialogValues.securityAreaID,
                AuditLog: {
                    Module: "Access Management",
                    Action: "Create Role",
                    Object: `Role Name: ${createRoleDialogValues.roleName} | Role Short Name: ${createRoleDialogValues.roleShortName} | Security Area ID: ${createRoleDialogValues.securityAreaID}`,
                    ActionBy: user
                }
            }
            await dispatch(createRole(newRole))
            await dispatch(getRoles())
            handleCloseCreateRoleDialog()
            clearCreateRoleDialogValues()
        }
    }

    // Manage Positions Dialog Handlers
    const handleClickManagePositions = () => {
        setPositionsDialogOpen(true)
    }

    const handleClosePositionsDialog = () => {
        setPositionsDialogOpen(false)
    }

    const handleChangePositionsDialog = (e) => {
        const name = e.target.name
        const value = e.target.value

        setPositionsDialogValues({
            ...positionsDialogValues,
            [name]: value
        })
    }

    // Create Position Dialog Handlers
    const handleClickCreatePosition = () => {
        clearCreatePositionDialogValues()
        setCreatePositionDialogOpen(true)
    }

    const handleCloseCreatePositionDialog = () => {
        setCreatePositionDialogOpen(false)
        clearCreatePositionDialogValues()
    }

    const handleChangeCreatePositionDialog = (e) => {
        const name = e.target.name
        const value = e.target.value

        setCreatePositionDialogValues({
            ...createPositionDialogValues,
            [name]: value
        })
    }

    const clearCreatePositionDialogValues = () => {
        setCreatePositionDialogValues({
            department: "",
            jobTitle: "",
            classification: "",
            roleID: "none"
        })
    }

    const handleCreatePosition = async () => {
        if (
            createPositionDialogValues.department !== "" &&
            createPositionDialogValues.jobTitle !== "" &&
            createPositionDialogValues.classification !== "" &&
            createPositionDialogValues.roleID !== "none"
        ) {
            const newPosition = {
                Department: createPositionDialogValues.department,
                JobTitle: createPositionDialogValues.jobTitle,
                Classification: createPositionDialogValues.classification,
                RoleID: createPositionDialogValues.roleID,
                AuditLog: {
                    Module: "Access Management",
                    Action: "Create Position",
                    Object: `Department: ${createPositionDialogValues.department} | Job Title: ${createPositionDialogValues.jobTitle} | Classification: ${createPositionDialogValues.classification} | Role ID: ${createPositionDialogValues.roleID}`,
                    ActionBy: user
                }
            }
            await dispatch(createPosition(newPosition))
            await dispatch(getPositions())
            handleCloseCreatePositionDialog()
            clearCreatePositionDialogValues()
        }
    }

    let filteredPositions = []
    if (positions && positions.length > 0) {
        filteredPositions = positions.filter((position) => {
            const jobTitleFilter =
                positionsDialogValues.jobTitleFilter.toLowerCase()
            const departmentFilter =
                positionsDialogValues.departmentFilter.toLowerCase()

            const jobTitleMatch =
                position.JobTitle &&
                position.JobTitle.toLowerCase().includes(jobTitleFilter)
            const departmentMatch =
                position.Department &&
                position.Department.toLowerCase().includes(departmentFilter)

            return jobTitleMatch && departmentMatch
        })
    } else {
        filteredPositions = positions
    }

    // Pending Positions Handlers
    const handleClickPendingPositions = () => {
        clearPendingPositionsDialog()
        setPendingPositionsDialogOpen(true)
    }

    const handleClosePendingPositionsDialog = () => {
        setPendingPositionsDialogOpen(false)
        clearPendingPositionsDialog()
    }

    const clearPendingPositionsDialog = () => {
        setPendingPositionsDialogValues({
            roleID: "none"
        })
    }

    const handleChangePendingPositionsDialog = (e) => {
        const name = e.target.name
        const value = e.target.value

        setPendingPositionsDialogValues({
            ...pendingPositionsDialogValues,
            [name]: value
        })
    }

    // Manage Exceptions Dialog Handlers
    const handleClickManageExceptions = () => {
        setExceptionsDialogOpen(true)
    }

    const handleCloseExceptionsDialog = () => {
        setExceptionsDialogOpen(false)
    }

    // Create Exception Dialog Handlers
    const handleClickCreateException = () => {
        clearCreateExceptionDialogValues()
        setCreateExceptionDialogOpen(true)
    }

    const handleCloseCreateExceptionDialog = () => {
        setCreateExceptionDialogOpen(false)
        clearCreateExceptionDialogValues()
    }

    const handleChangeCreateExceptionDialog = (e) => {
        const name = e.target.name
        const value = e.target.value

        setCreateExceptionDialogValues({
            ...createExceptionDialogValues,
            [name]: value
        })
    }

    const clearCreateExceptionDialogValues = () => {
        setCreateExceptionDialogValues({
            associateOID: "none",
            permissionID: "none",
            exceptionReason: ""
        })
    }

    const handleCreateException = async () => {
        if (
            createExceptionDialogValues.associateOID !== "none" &&
            createExceptionDialogValues.permissionID !== "none" &&
            createExceptionDialogValues.exceptionReason !== ""
        ) {
            const newException = {
                AssociateOID: createExceptionDialogValues.associateOID,
                PermissionID: createExceptionDialogValues.permissionID,
                ExceptionReason: createExceptionDialogValues.exceptionReason,
                AuditLog: {
                    Module: "Access Management",
                    Action: "Create Exception",
                    Object: `AssociateOID: ${createExceptionDialogValues.associateOID} | Permission ID: ${createExceptionDialogValues.permissionID} | Exception Reason: ${createExceptionDialogValues.exceptionReason}`,
                    ActionBy: user
                }
            }
            await dispatch(createException(newException))
            await dispatch(getExceptions())
            handleCloseCreateExceptionDialog()
            clearCreateExceptionDialogValues()
        }
    }

    return (
        <Grid container justifyContent="center">
            <Stack spacing={2} style={{ marginBottom: "30px" }}>
                {/* ROLES */}
                <Card align="center">
                    <CardContent>
                        <Typography variant="h6" align="center" gutterBottom>
                            Roles
                        </Typography>
                        {userPermissions.EditAccessManagement && (
                            <Button
                                variant="contained"
                                onClick={handleClickCreateRole}
                            >
                                Create Role
                            </Button>
                        )}
                    </CardContent>
                    <CardContent>
                        <FormControl
                            variant="standard"
                            style={{ marginTop: "5px" }}
                        >
                            <Select
                                id="role-filter"
                                value={roleFilter}
                                onChange={handleChangeRoleFilter}
                            >
                                <MenuItem value="none">
                                    <em>Select Filter</em>
                                </MenuItem>
                                {securityAreas.map((securityArea) => (
                                    <MenuItem
                                        value={securityArea.SecurityAreaID}
                                    >
                                        {securityArea.SecurityAreaName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Tooltip title="Clear Filter">
                            <IconButton onClick={handleClickClearFilter}>
                                <FilterAltOffIcon />
                            </IconButton>
                        </Tooltip>
                    </CardContent>
                </Card>
                {/* POSITIONS */}
                <Card align="center">
                    <CardContent>
                        <Typography variant="h6" align="center" gutterBottom>
                            Positions
                        </Typography>
                        <Button
                            variant="contained"
                            onClick={handleClickManagePositions}
                        >
                            Manage Positions
                        </Button>
                    </CardContent>
                    <CardContent>
                        <Typography variant="subtitle1" align="center">
                            Pending Positions:
                            <Tooltip title="View Pending Positions">
                                <IconButton
                                    onClick={handleClickPendingPositions}
                                >
                                    <Badge
                                        badgeContent={adpPositionsDelta.length}
                                        color="secondary"
                                    >
                                        <EmojiPeopleIcon />
                                    </Badge>
                                </IconButton>
                            </Tooltip>
                        </Typography>
                    </CardContent>
                </Card>
                {/* SECURITY AREAS */}
                <Card align="center">
                    <CardContent>
                        <Typography variant="h6" align="center" gutterBottom>
                            Security Areas
                        </Typography>
                        <Button
                            variant="contained"
                            onClick={handleClickManageSecurityAreas}
                        >
                            Manage Security Areas
                        </Button>
                    </CardContent>
                </Card>
                {/* Exceptions */}
                <Card align="center">
                    <CardContent>
                        <Typography variant="h6" align="center" gutterBottom>
                            Exceptions
                        </Typography>
                        <Button
                            variant="contained"
                            onClick={handleClickManageExceptions}
                        >
                            Manage Exceptions
                        </Button>
                    </CardContent>
                </Card>
            </Stack>

            {/* Manage Security Areas Dialog */}
            <Dialog
                open={securityAreasDialogOpen}
                onClose={handleCloseSecurityAreasDialog}
                fullWidth={true}
                maxWidth="md"
                style={{ marginTop: "5vh" }}
            >
                <DialogTitle>Manage Security Areas</DialogTitle>
                <DialogContent>
                    <Stack spacing={1}>
                        {securityAreas.map((securityArea) => (
                            <SecurityArea
                                securityAreaID={securityArea.SecurityAreaID}
                                securityAreaName={securityArea.SecurityAreaName}
                                securityAreaOwnerAOID={
                                    securityArea.SecurityAreaOwnerAOID
                                }
                                securityAreaOwner={
                                    securityArea.SecurityAreaOwner
                                }
                            />
                        ))}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    {userPermissions.EditAccessManagement && (
                        <Button
                            onClick={handleClickCreateSecurityArea}
                            color="secondary"
                        >
                            Create Security Area
                        </Button>
                    )}
                    <Button
                        onClick={handleCloseSecurityAreasDialog}
                        color="primary"
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Create Security Area Dialog */}
            <Dialog
                open={createSecurityAreaDialogOpen}
                onClose={handleCloseCreateSecurityAreaDialog}
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle>Create Security Area</DialogTitle>
                <DialogContent>
                    {/* Security Area Name Field */}
                    <TextField
                        autoFocus
                        margin="dense"
                        id="securityAreaName"
                        label="Security Area Name"
                        type="text"
                        name="securityAreaName"
                        value={createSecurityAreaDialogValues.securityAreaName}
                        onChange={handleChangeCreateSecurityAreaDialog}
                        fullWidth
                    />
                    {/* Security Owner Field */}
                    <FormControl fullWidth={true} style={{ marginTop: "20px" }}>
                        <InputLabel htmlFor="securityAreaOwnerAOID">
                            Security Area Owner
                        </InputLabel>
                        <Select
                            native
                            value={
                                createSecurityAreaDialogValues.securityAreaOwnerAOID
                            }
                            onChange={handleChangeCreateSecurityAreaDialog}
                            label="Security Area Owner"
                            inputProps={{
                                name: "securityAreaOwnerAOID",
                                id: "securityAreaOwnerAOID"
                            }}
                        >
                            <option key="none" value="none">
                                Select an Owner
                            </option>
                            {employees.map((employee) => (
                                <option
                                    key={employee.AssociateOID}
                                    value={employee.AssociateOID}
                                >
                                    {`${employee.FirstName} ${employee.LastName}`}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCreateSecurityArea}
                        color="secondary"
                    >
                        Create
                    </Button>
                    <Button
                        onClick={handleCloseCreateSecurityAreaDialog}
                        color="primary"
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Create Role Dialog */}
            <Dialog
                open={createRoleDialogOpen}
                onClose={handleCloseCreateRoleDialog}
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle>Create Role</DialogTitle>
                <DialogContent>
                    {/* Role Name Field */}
                    <TextField
                        autoFocus
                        margin="dense"
                        id="roleName"
                        label="Role Name"
                        type="text"
                        name="roleName"
                        value={createRoleDialogValues.roleName}
                        onChange={handleChangeCreateRoleDialog}
                        fullWidth
                    />
                    {/* Role Short Name Field */}
                    <TextField
                        margin="dense"
                        id="roleShortName"
                        label="Role Short Name"
                        type="text"
                        name="roleShortName"
                        value={createRoleDialogValues.roleShortName}
                        onChange={handleChangeCreateRoleDialog}
                        fullWidth
                    />
                    {/* Security Area Field */}
                    <FormControl fullWidth={true} style={{ marginTop: "20px" }}>
                        <InputLabel htmlFor="securityAreaID">
                            Security Area
                        </InputLabel>
                        <Select
                            native
                            value={createRoleDialogValues.securityAreaID}
                            onChange={handleChangeCreateRoleDialog}
                            label="Security Area"
                            inputProps={{
                                name: "securityAreaID",
                                id: "securityAreaID"
                            }}
                        >
                            <option key="none" value="none">
                                Select a Security Area
                            </option>
                            {securityAreas.map((securityArea) => (
                                <option
                                    key={securityArea.SecurityAreaID}
                                    value={securityArea.SecurityAreaID}
                                >
                                    {securityArea.SecurityAreaName}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCreateRole} color="secondary">
                        Create
                    </Button>
                    <Button
                        onClick={handleCloseCreateRoleDialog}
                        color="primary"
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Manage Positions Dialog */}
            <Dialog
                open={positionsDialogOpen}
                onClose={handleClosePositionsDialog}
                fullWidth={true}
                maxWidth="md"
                style={{ marginTop: "5vh" }}
            >
                <DialogTitle>Manage Positions</DialogTitle>
                <DialogContent style={{ height: "78vh" }}>
                    <Grid
                        container
                        spacing={2}
                        style={{ marginBottom: "20px" }}
                    >
                        <Grid
                            item
                            xs={12}
                            md={6}
                            style={{
                                paddingLeft: "25px",
                                paddingRight: "25px"
                            }}
                        >
                            {/* Department Filter */}
                            <TextField
                                margin="dense"
                                id="departmentFilter"
                                label="Department Filter"
                                type="text"
                                name="departmentFilter"
                                value={positionsDialogValues.departmentFilter}
                                onChange={handleChangePositionsDialog}
                                fullWidth
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            style={{
                                paddingLeft: "25px",
                                paddingRight: "25px"
                            }}
                        >
                            {/* Job Title Filter */}
                            <TextField
                                margin="dense"
                                id="jobTitleFilter"
                                label="Job Title Filter"
                                type="text"
                                name="jobTitleFilter"
                                value={positionsDialogValues.jobTitleFilter}
                                onChange={handleChangePositionsDialog}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Stack spacing={1}>
                        {filteredPositions.map((position) => (
                            <Position
                                positionID={position.PositionID}
                                roleID={position.RoleID}
                                department={position.Department}
                                jobTitle={position.JobTitle}
                                classification={position.Classification}
                                roleName={position.RoleName}
                                containsActiveEmployee={
                                    position.ContainsActiveEmployee
                                }
                            />
                        ))}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    {userPermissions.EditAccessManagement && (
                        <Button
                            onClick={handleClickCreatePosition}
                            color="secondary"
                        >
                            Create
                        </Button>
                    )}
                    <Button
                        onClick={handleClosePositionsDialog}
                        color="primary"
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Pending Positions Dialog */}
            <Dialog
                open={pendingPositionsDialogOpen}
                onClose={handleClosePendingPositionsDialog}
                fullWidth={true}
                maxWidth="md"
                style={{ marginTop: "5vh" }}
            >
                <DialogTitle>Pending Positions</DialogTitle>
                <DialogContent>
                    {adpPositionsDelta.length === 0 ? (
                        <Typography variant="subtitle1">
                            All active positions have been assigned to a role
                        </Typography>
                    ) : (
                        <>
                            {userPermissions.EditAccessManagement && (
                                <>
                                    <Typography variant="subtitle1">
                                        Select a role to assign positions to:
                                    </Typography>

                                    {/* Pending Position Role Field */}
                                    <FormControl
                                        fullWidth={true}
                                        style={{
                                            marginTop: "20px",
                                            marginBottom: "30px"
                                        }}
                                    >
                                        <InputLabel htmlFor="roleID">
                                            Role
                                        </InputLabel>
                                        <Select
                                            native
                                            value={
                                                pendingPositionsDialogValues.roleID
                                            }
                                            onChange={
                                                handleChangePendingPositionsDialog
                                            }
                                            label="Role"
                                            inputProps={{
                                                name: "roleID",
                                                id: "roleID"
                                            }}
                                        >
                                            <option key="none" value="none">
                                                Select a Role
                                            </option>
                                            {roles.map((role) => (
                                                <option
                                                    key={role.RoleID}
                                                    value={role.RoleID}
                                                >
                                                    {role.RoleName}
                                                </option>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </>
                            )}

                            <Stack spacing={1}>
                                {adpPositionsDelta.map((position) => (
                                    <PendingPosition
                                        department={position.Department}
                                        jobTitle={position.JobTitle}
                                        classification={position.Classification}
                                        ppRoleID={
                                            pendingPositionsDialogValues.roleID
                                        }
                                    />
                                ))}
                            </Stack>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClosePendingPositionsDialog}
                        color="primary"
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Create Position Dialog */}
            <Dialog
                open={createPositionDialogOpen}
                onClose={handleCloseCreatePositionDialog}
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle>Create Position</DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle1" gutterBottom>
                        NOTE: This form should only be used to create a position
                        when the employee has not started and their position is
                        not yet available in the pending positions list
                    </Typography>
                    {/* Department Field */}
                    <TextField
                        autoFocus
                        margin="dense"
                        id="department"
                        label="Department"
                        type="text"
                        name="department"
                        value={createPositionDialogValues.department}
                        onChange={handleChangeCreatePositionDialog}
                        fullWidth
                    />
                    {/* Job Title Field */}
                    <TextField
                        margin="dense"
                        id="jobTitle"
                        label="Job Title"
                        type="text"
                        name="jobTitle"
                        value={createPositionDialogValues.jobTitle}
                        onChange={handleChangeCreatePositionDialog}
                        fullWidth
                    />
                    {/* Classification Field */}
                    <TextField
                        margin="dense"
                        id="classification"
                        label="Classification"
                        type="text"
                        name="classification"
                        value={createPositionDialogValues.classification}
                        onChange={handleChangeCreatePositionDialog}
                        fullWidth
                    />
                    {/* Role Field */}
                    <FormControl fullWidth={true} style={{ marginTop: "20px" }}>
                        <InputLabel htmlFor="roleID">Role</InputLabel>
                        <Select
                            native
                            value={createPositionDialogValues.roleID}
                            onChange={handleChangeCreatePositionDialog}
                            label="Role"
                            inputProps={{
                                name: "roleID",
                                id: "roleID"
                            }}
                        >
                            <option key="none" value="none">
                                Select a Role
                            </option>
                            {roles.map((role) => (
                                <option key={role.RoleID} value={role.RoleID}>
                                    {role.RoleName}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCreatePosition} color="secondary">
                        Create
                    </Button>
                    <Button
                        onClick={handleCloseCreatePositionDialog}
                        color="primary"
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Manage Exceptions Dialog */}
            <Dialog
                open={exceptionsDialogOpen}
                onClose={handleCloseExceptionsDialog}
                fullWidth={true}
                maxWidth="md"
                style={{ marginTop: "5vh" }}
            >
                <DialogTitle>Manage Exceptions</DialogTitle>
                <DialogContent>
                    <Stack spacing={1}>
                        {exceptions.map((exception) => (
                            <Exception
                                exceptionID={exception.ExceptionID}
                                associateOID={exception.AssociateOID}
                                permissionID={exception.PermissionID}
                                exceptionReason={exception.ExceptionReason}
                                fullName={exception.FullName}
                                permission={exception.Permission}
                            />
                        ))}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    {userPermissions.EditAccessManagement && (
                        <Button
                            onClick={handleClickCreateException}
                            color="secondary"
                        >
                            Create Exception
                        </Button>
                    )}
                    <Button
                        onClick={handleCloseExceptionsDialog}
                        color="primary"
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Create Exception Dialog */}
            <Dialog
                open={createExceptionDialogOpen}
                onClose={handleCloseCreateExceptionDialog}
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle>Create Exception</DialogTitle>
                <DialogContent>
                    {/* AssociateOID Field */}
                    <FormControl fullWidth={true} style={{ marginTop: "20px" }}>
                        <InputLabel htmlFor="associateOID">Employee</InputLabel>
                        <Select
                            native
                            value={createExceptionDialogValues.associateOID}
                            onChange={handleChangeCreateExceptionDialog}
                            label="Employee"
                            inputProps={{
                                name: "associateOID",
                                id: "associateOID"
                            }}
                        >
                            <option key="none" value="none">
                                Select an Employee
                            </option>
                            {employees.map((employee) => (
                                <option
                                    key={employee.AssociateOID}
                                    value={employee.AssociateOID}
                                >
                                    {`${employee.FirstName} ${employee.LastName}`}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                    {/* PermissionID Field */}
                    <FormControl fullWidth={true} style={{ marginTop: "20px" }}>
                        <InputLabel htmlFor="permissionID">
                            Permission
                        </InputLabel>
                        <Select
                            native
                            value={createExceptionDialogValues.permissionID}
                            onChange={handleChangeCreateExceptionDialog}
                            label="Permission"
                            inputProps={{
                                name: "permissionID",
                                id: "permissionID"
                            }}
                        >
                            <option key="none" value="none">
                                Select a Permission
                            </option>
                            {permissions.map((permission) => (
                                <option
                                    key={permission.PermissionID}
                                    value={permission.PermissionID}
                                >
                                    {`${permission.Category} | ${permission.Application} | ${permission.Module} | ${permission.PermissionLevel}`}
                                </option>
                            ))}
                        </Select>
                    </FormControl>

                    {/* Exception Reason Field */}
                    <TextField
                        autoFocus
                        margin="dense"
                        id="exceptionReason"
                        label="Exception Reason"
                        type="text"
                        name="exceptionReason"
                        value={createExceptionDialogValues.exceptionReason}
                        onChange={handleChangeCreateExceptionDialog}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCreateException} color="secondary">
                        Create
                    </Button>
                    <Button
                        onClick={handleCloseCreateExceptionDialog}
                        color="primary"
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}

export default RolesPageSide
