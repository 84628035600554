import axios from "axios"

const getCookie = (name) => {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop().split(";").shift()
}

export default function resetHeaders() {
    const sessionID = getCookie("__sessionid")
    const googleID = getCookie("__uid")
    axios.defaults.headers.common["session"] = sessionID
    axios.defaults.headers.common["gid"] = googleID
}
