import {
    CREATE_POSITION,
    GET_ADP_POSITIONS_DELTA,
    GET_POSITIONS,
    UPDATE_POSITION,
    DELETE_POSITION
} from "./types"
import axios from "../auth/axiosAuth"
import { backendURL } from "../config"

export const getPositions = () => async (dispatch) => {
    const res = await axios.get(`${backendURL}/position`)
    dispatch({ type: GET_POSITIONS, payload: res.data })
}

export const getADPPositionsDelta = () => async (dispatch) => {
    const res = await axios.get(`${backendURL}/position/adp/delta`)
    dispatch({ type: GET_ADP_POSITIONS_DELTA, payload: res.data })
}

export const createPosition = (newPosition) => async (dispatch) => {
    await axios.post(`${backendURL}/position`, newPosition)
    dispatch({ type: CREATE_POSITION })
}

export const updatePosition = (updatedPosition) => async (dispatch) => {
    await axios.put(`${backendURL}/position`, updatedPosition)
    dispatch({ type: UPDATE_POSITION })
}

export const deletePosition = (positionToDelete) => async (dispatch) => {
    await axios.delete(`${backendURL}/position`, {
        data: positionToDelete
    })
    dispatch({ type: DELETE_POSITION })
}
