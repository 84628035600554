import {
    CREATE_PERMISSION,
    DELETE_PERMISSION,
    GET_PERMISSIONS,
    UPDATE_PERMISSION
} from "../actions/types"

const initialState = {
    permissions: []
}

const permissionReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PERMISSIONS:
            return {
                ...state,
                permissions: action.payload
            }
        case CREATE_PERMISSION:
            return {
                ...state
            }
        case UPDATE_PERMISSION:
            return {
                ...state
            }
        case DELETE_PERMISSION:
            return {
                ...state
            }
        default:
            return state
    }
}

export default permissionReducer
