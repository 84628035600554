import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
    Card,
    Grid,
    IconButton,
    Tooltip,
    Typography,
    Collapse,
    CardContent,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    FormControl,
    InputLabel,
    Select,
    Button,
    CardHeader,
    Checkbox,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    FormLabel
} from "@material-ui/core"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import EnhancedEncryptionIcon from "@mui/icons-material/EnhancedEncryption"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { ExpandMore } from "../../main/ExpandMore"
import { deleteRole, getRoles, updateRole } from "../../../actions/roleActions"
import {
    cloneRolePermissions,
    getRolePermissions,
    replaceRolePermissions
} from "../../../actions/rolePermissionActions"

// Transfer List Functions
function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1)
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1)
}

function union(a, b) {
    return [...a, ...not(b, a)]
}

function Role(props) {
    const {
        roleID,
        roleName,
        roleShortName,
        securityAreaID,
        securityAreaName
    } = props
    const dispatch = useDispatch()

    // Store Variables
    const securityAreas = useSelector(
        (state) => state.securityAreas.securityAreas
    )
    const positions = useSelector((state) => state.positions.positions)
    const rolePermissions = useSelector(
        (state) => state.rolePermissions.rolePermissions
    )
    const permissions = useSelector((state) => state.permissions.permissions)
    const roles = useSelector((state) => state.roles.roles)
    const user = useSelector((state) => state.auth.user.email)
    const userPermissions = useSelector((state) => state.auth.permissions)

    // State Variables
    const [expanded, setExpanded] = useState(false)

    // Edit Role Dialog State
    const [editRoleDialogOpen, setEditRoleDialogOpen] = useState(false)
    const [editRoleDialogValues, setEditRoleDialogValues] = useState({
        roleID: "",
        roleName: "",
        roleShortName: "",
        securityAreaID: "none"
    })

    // Delete Dialog State
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [deleteDialogValues, setDeleteDialogValues] = useState({
        roleID: "",
        roleName: "",
        confirmDelete: ""
    })

    // Clone Dialog State
    const [cloneDialogOpen, setCloneDialogOpen] = useState(false)
    const [cloneDialogValues, setCloneDialogValues] = useState({
        cloneFromRoleID: "none"
    })

    // Edit Role Permissions Dialog State
    const [editRolePermissionsDialogOpen, setEditRolePermissionsDialogOpen] =
        useState(false)

    // Transfer List State and Variables
    const [checked, setChecked] = useState([])
    const [left, setLeft] = useState([])
    const [right, setRight] = useState([])

    const leftChecked = intersection(checked, left)
    const rightChecked = intersection(checked, right)
    const numberOfChecked = (items) => intersection(checked, items).length

    // Handlers
    const handleClickExpand = () => {
        setExpanded(!expanded)
    }

    // Edit Role Dialog Handlers
    const handleClickEditRole = () => {
        setEditRoleDialogValues({
            roleID,
            roleName,
            roleShortName,
            securityAreaID
        })
        setEditRoleDialogOpen(true)
    }

    const handleCloseEditRoleDialog = () => {
        setEditRoleDialogOpen(false)
        clearEditRoleDialogValues()
    }

    const handleChangeEditRoleDialog = (e) => {
        const name = e.target.name
        const value = e.target.value

        setEditRoleDialogValues({
            ...editRoleDialogValues,
            [name]: value
        })
    }

    const clearEditRoleDialogValues = () => {
        setEditRoleDialogValues({
            roleID: "",
            roleName: "",
            roleShortName: "",
            securityAreaID: "none"
        })
    }

    const handleUpdateRole = async () => {
        if (
            editRoleDialogValues.roleName !== "" &&
            editRoleDialogValues.roleShortName !== "" &&
            editRoleDialogValues.securityAreaID !== "none"
        ) {
            const updatedRole = {
                RoleID: editRoleDialogValues.roleID,
                RoleName: editRoleDialogValues.roleName,
                RoleShortName: editRoleDialogValues.roleShortName,
                SecurityAreaID: editRoleDialogValues.securityAreaID,
                AuditLog: {
                    Module: "Access Management",
                    Action: "Update Role",
                    Object: `Role ID: ${roleID} | Role Name: ${editRoleDialogValues.roleName} | Role Short Name: ${editRoleDialogValues.roleShortName} | Security Area ID: ${editRoleDialogValues.securityAreaID}`,
                    ActionBy: user
                }
            }
            await dispatch(updateRole(updatedRole))
            await dispatch(getRoles())
            handleCloseEditRoleDialog()
            clearEditRoleDialogValues()
        }
    }

    // Delete Dialog Handlers
    const handleClickDelete = () => {
        setDeleteDialogValues({
            roleID,
            roleName,
            confirmDelete: ""
        })
        setDeleteDialogOpen(true)
    }

    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false)
        clearDeleteDialogValues()
    }

    const clearDeleteDialogValues = () => {
        setDeleteDialogValues({
            roleID: "",
            roleName: "",
            confirmDelete: ""
        })
    }

    const handleDeleteRole = async () => {
        if (
            deleteDialogValues.confirmDelete !== "" &&
            deleteDialogValues.confirmDelete === deleteDialogValues.roleName
        ) {
            const roleToDelete = {
                RoleID: deleteDialogValues.roleID,
                AuditLog: {
                    Module: "Access Management",
                    Action: "Delete Role",
                    Object: `Role ID: ${roleID} | Role: ${roleName}`,
                    ActionBy: user
                }
            }
            await dispatch(deleteRole(roleToDelete))
            await dispatch(getRoles())
            handleCloseDeleteDialog()
            clearDeleteDialogValues()
        }
    }

    const handleChangeDeleteDialog = (e) => {
        const name = e.target.name
        const value = e.target.value

        setDeleteDialogValues({
            ...deleteDialogValues,
            [name]: value
        })
    }

    // Edit Role Permissions Dialog Handlers
    const handleClickEditRolePermissions = () => {
        const includedPermissions = rolePermissions.filter(
            (permission) => permission.RoleID === roleID
        )
        setLeft(
            permissions.filter(
                (permission) =>
                    !includedPermissions.some(
                        (includedPermission) =>
                            includedPermission.PermissionID ===
                            permission.PermissionID
                    )
            )
        )
        setRight(includedPermissions)
        setEditRolePermissionsDialogOpen(true)
    }

    const handleCloseEditRolePermissionsDialog = () => {
        setEditRolePermissionsDialogOpen(false)
        setLeft([])
        setRight([])
    }

    const handleUpdateRolePermissions = async () => {
        if (right.length > 0) {
            const newPermissions = right.map(
                (permission) => permission.PermissionID
            )
            const replacementObject = {
                RoleID: roleID,
                PermissionIDs: newPermissions.join(","),
                AuditLog: {
                    Module: "Access Management",
                    Action: "Update Role Permissions",
                    Object: `Role ID: ${roleID} | Role: ${roleName} | Permission IDs: ${newPermissions.join(
                        ","
                    )}`,
                    ActionBy: user
                }
            }
            console.log(replacementObject)
            await dispatch(replaceRolePermissions(replacementObject))
            await dispatch(getRolePermissions())
            handleCloseEditRolePermissionsDialog()
        }
    }

    // Clone Dialog Handlers
    const handleClickClone = () => {
        clearCloneDialogValues()
        setCloneDialogOpen(true)
    }

    const handleCloseCloneDialog = () => {
        setCloneDialogOpen(false)
        clearCloneDialogValues()
    }

    const clearCloneDialogValues = () => {
        setCloneDialogValues({
            cloneFromRoleID: "none"
        })
    }

    const handleClone = async () => {
        if (cloneDialogValues.cloneFromRoleID !== "none") {
            const roleToClone = {
                CloneToID: roleID,
                CloneFromID: cloneDialogValues.cloneFromRoleID,
                AuditLog: {
                    Module: "Access Management",
                    Action: "Clone Role",
                    Object: `Clone To ID: ${roleID} | Clone To Role: ${roleName} | Clone From ID: ${cloneDialogValues.cloneFromRoleID}`,
                    ActionBy: user
                }
            }
            console.log(roleToClone)
            await dispatch(cloneRolePermissions(roleToClone))
            await dispatch(getRolePermissions())
            handleCloseCloneDialog()
            handleCloseEditRolePermissionsDialog()
        }
    }

    const handleChangeCloneDialog = (e) => {
        const name = e.target.name
        const value = e.target.value

        setCloneDialogValues({
            ...cloneDialogValues,
            [name]: value
        })
    }

    // Transfer List Handlers
    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value)
        const newChecked = [...checked]

        if (currentIndex === -1) {
            newChecked.push(value)
        } else {
            newChecked.splice(currentIndex, 1)
        }

        setChecked(newChecked)
    }

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items))
        } else {
            setChecked(union(checked, items))
        }
    }

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked))
        setLeft(not(left, leftChecked))
        setChecked(not(checked, leftChecked))
    }

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked))
        setRight(not(right, rightChecked))
        setChecked(not(checked, rightChecked))
    }

    // Transfer List Component
    const customList = (title, items) => (
        <Card>
            <CardHeader
                sx={{ px: 2, py: 1 }}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={
                            numberOfChecked(items) === items.length &&
                            items.length !== 0
                        }
                        indeterminate={
                            numberOfChecked(items) !== items.length &&
                            numberOfChecked(items) !== 0
                        }
                        disabled={items.length === 0}
                        inputProps={{
                            "aria-label": "all items selected"
                        }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selected`}
            />
            <Divider />
            <List
                sx={{
                    width: 200,
                    height: 230,
                    bgcolor: "background.paper",
                    overflow: "auto"
                }}
                dense
                component="div"
                role="list"
            >
                {items.map((permission) => {
                    const labelId = `transfer-list-all-item-${permission.PermissionID}-label`

                    return (
                        <ListItem
                            key={permission.PermissionID}
                            role="listitem"
                            button
                            onClick={handleToggle(permission)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(permission) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        "aria-labelledby": labelId
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                id={labelId}
                                primary={`${permission.Category} | ${permission.Application} | ${permission.Module} | ${permission.PermissionLevel}`}
                            />
                        </ListItem>
                    )
                })}
            </List>
        </Card>
    )

    return (
        <Card>
            <Grid container>
                {/* Grid Left Side */}
                <Grid item xs={6}>
                    <Typography
                        variant="subtitle1"
                        style={{
                            marginTop: "9px",
                            marginLeft: "14px"
                        }}
                    >
                        {roleName}
                    </Typography>
                </Grid>
                {/* Grid Right Side */}
                <Grid item align="right" xs={6}>
                    {userPermissions.EditAccessManagement && (
                        <Tooltip title="Edit Role">
                            <IconButton onClick={handleClickEditRole}>
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    {userPermissions.EditAccessManagement && (
                        <Tooltip title="Edit Role Permissions">
                            <IconButton
                                onClick={handleClickEditRolePermissions}
                            >
                                <EnhancedEncryptionIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    {userPermissions.EditAccessManagement && (
                        <Tooltip title="Delete Role">
                            <IconButton onClick={handleClickDelete}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    <ExpandMore
                        expand={expanded}
                        onClick={handleClickExpand}
                        aria-expanded={expanded}
                    >
                        <Tooltip title={!expanded ? "Show More" : "Show Less"}>
                            <ExpandMoreIcon />
                        </Tooltip>
                    </ExpandMore>
                </Grid>
            </Grid>

            {/* Expand More Content */}
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Typography variant="subtitle2">{`Security Area: ${securityAreaName}`}</Typography>
                    <Typography variant="subtitle2">{`Short Name: ${roleShortName}`}</Typography>
                    <br />
                    <Typography variant="subtitle2">
                        Included Positions:
                    </Typography>
                    {positions
                        .filter((position) => position.RoleID === roleID)
                        .map((position) => (
                            <Typography variant="body2">
                                {`${position.Department} | ${position.JobTitle} | ${position.Classification}`}
                            </Typography>
                        ))}
                    <br />
                    <Typography variant="subtitle2">
                        Included Permissions:
                        {rolePermissions
                            .filter(
                                (permission) => permission.RoleID === roleID
                            )
                            .map((permission) => (
                                <Typography variant="body2">
                                    {`${permission.Category} | ${permission.Application} | ${permission.Module} | ${permission.PermissionLevel}`}
                                </Typography>
                            ))}
                    </Typography>
                </CardContent>
            </Collapse>
            {/* Edit Role Dialog */}
            <Dialog
                open={editRoleDialogOpen}
                onClose={handleCloseEditRoleDialog}
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle>Edit Role</DialogTitle>
                <DialogContent>
                    {/* Role Name Field */}
                    <TextField
                        autoFocus
                        margin="dense"
                        id="roleName"
                        label="Role Name"
                        type="text"
                        name="roleName"
                        value={editRoleDialogValues.roleName}
                        onChange={handleChangeEditRoleDialog}
                        fullWidth
                    />
                    {/* Role Short Name Field */}
                    <TextField
                        margin="dense"
                        id="roleShortName"
                        label="Role Short Name"
                        type="text"
                        name="roleShortName"
                        value={editRoleDialogValues.roleShortName}
                        onChange={handleChangeEditRoleDialog}
                        fullWidth
                    />
                    {/* Security Area Field */}
                    <FormControl fullWidth={true} style={{ marginTop: "20px" }}>
                        <InputLabel htmlFor="securityAreaID">
                            Security Area
                        </InputLabel>
                        <Select
                            native
                            value={editRoleDialogValues.securityAreaID}
                            onChange={handleChangeEditRoleDialog}
                            label="Security Area"
                            inputProps={{
                                name: "securityAreaID",
                                id: "securityAreaID"
                            }}
                        >
                            <option key="none" value="none">
                                Select a Security Area
                            </option>
                            {securityAreas.map((securityArea) => (
                                <option
                                    key={securityArea.SecurityAreaID}
                                    value={securityArea.SecurityAreaID}
                                >
                                    {securityArea.SecurityAreaName}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUpdateRole} color="secondary">
                        Update
                    </Button>
                    <Button onClick={handleCloseEditRoleDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete Dialog */}
            <Dialog
                open={deleteDialogOpen}
                onClose={handleCloseDeleteDialog}
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle>Delete Role</DialogTitle>
                <DialogContent>
                    <strong>WARNING: This action cannot be reversed!</strong>
                    <br /> Are you sure you want to delete this role?
                    {/* Confirm Delete Field */}
                    <TextField
                        margin="dense"
                        id="confirmDelete"
                        label="Enter role name to confirm"
                        type="text"
                        name="confirmDelete"
                        value={deleteDialogValues.confirmDelete}
                        onChange={handleChangeDeleteDialog}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteRole} color="secondary">
                        Delete
                    </Button>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Edit Role Permissions Dialog */}
            <Dialog
                open={editRolePermissionsDialogOpen}
                onClose={handleCloseEditRolePermissionsDialog}
                fullWidth={true}
                maxWidth="xl"
                style={{ marginTop: "5vh" }}
            >
                <DialogTitle>Edit Role Permissions</DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle1">{`Editing permissions for: ${roleName}`}</Typography>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} md={5}>
                            {customList("Available Permissions", left)}
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <Grid
                                container
                                direction="column"
                                alignItems="center"
                            >
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleCheckedRight}
                                    disabled={leftChecked.length === 0}
                                    aria-label="move selected right"
                                >
                                    &gt;
                                </Button>
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleCheckedLeft}
                                    disabled={rightChecked.length === 0}
                                    aria-label="move selected left"
                                >
                                    &lt;
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            {customList("Assigned Permissions", right)}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClickClone}>
                        Clone From Another Role
                    </Button>
                    <Button
                        onClick={handleUpdateRolePermissions}
                        color="secondary"
                    >
                        Update
                    </Button>
                    <Button
                        onClick={handleCloseEditRolePermissionsDialog}
                        color="primary"
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Clone Dialog */}
            <Dialog
                open={cloneDialogOpen}
                onClose={handleCloseCloneDialog}
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle>Clone From Another Role</DialogTitle>
                <DialogContent>
                    <strong>WARNING: This action cannot be reversed!</strong>
                    <br />
                    <br />
                    <FormLabel>Clone From Role:</FormLabel>
                    {/* Clone From Field */}
                    <FormControl
                        fullWidth={true}
                        style={{ marginBottom: "20px" }}
                    >
                        {/* <InputLabel htmlFor="cloneFromRoleID">Role</InputLabel> */}
                        <Select
                            native
                            value={cloneDialogValues.cloneFromRoleID}
                            onChange={handleChangeCloneDialog}
                            label="Role"
                            inputProps={{
                                name: "cloneFromRoleID",
                                id: "cloneFromRoleID"
                            }}
                        >
                            <option key="none" value="none">
                                Select a Role
                            </option>
                            {roles.map((role) => (
                                <option key={role.RoleID} value={role.RoleID}>
                                    {role.RoleName}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                    <FormLabel>Clone To Role:</FormLabel>
                    <Typography variant="body1">{roleName}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClone} color="secondary">
                        Clone
                    </Button>
                    <Button onClick={handleCloseCloneDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Card>
    )
}

export default Role
