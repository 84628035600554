import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import LoginPrompt from "../../login/LoginPrompt"
import PermissionsPageMain from "./PermissionsPageMain"
import { getPermissions } from "../../../actions/permissionActions"
import { getRolePermissions } from "../../../actions/rolePermissionActions"
import { getRoles } from "../../../actions/roleActions"

function PermissionsPage() {
    const dispatch = useDispatch()

    // Store Variables
    const loggedIn = useSelector(
        (state) => state.auth.user && state.auth.user.auth
    )
    const authenticated = useSelector((state) => state.auth.authenticated)

    useEffect(() => {
        if (loggedIn && authenticated) {
            async function runUseEffectFunctions() {
                await dispatch(getPermissions())
                await dispatch(getRolePermissions())
                await dispatch(getRoles())
            }
            runUseEffectFunctions()
        }
        return () => {}
    }, [dispatch, loggedIn, authenticated])

    return <>{authenticated ? <PermissionsPageMain /> : <LoginPrompt />}</>
}

export default PermissionsPage
