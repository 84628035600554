import {
    GET_SECURITY_AREAS,
    CREATE_SECURITY_AREA,
    UPDATE_SECURITY_AREA,
    DELETE_SECURITY_AREA
} from "./types"
import axios from "../auth/axiosAuth"
import { backendURL } from "../config"

export const getSecurityAreas = () => async (dispatch) => {
    const res = await axios.get(`${backendURL}/security-area`)
    dispatch({ type: GET_SECURITY_AREAS, payload: res.data })
}

export const createSecurityArea = (newSecurityArea) => async (dispatch) => {
    await axios.post(`${backendURL}/security-area`, newSecurityArea)
    dispatch({ type: CREATE_SECURITY_AREA })
}

export const updateSecurityArea = (updatedSecurityArea) => async (dispatch) => {
    await axios.put(`${backendURL}/security-area`, updatedSecurityArea)
    dispatch({ type: UPDATE_SECURITY_AREA })
}

export const deleteSecurityArea =
    (securityAreaToDelete) => async (dispatch) => {
        await axios.delete(`${backendURL}/security-area`, {
            data: securityAreaToDelete
        })
        dispatch({ type: DELETE_SECURITY_AREA })
    }
