import { combineReducers } from "redux"
import authReducer from "./authReducer"
import roleReducer from "./roleReducer"
import securityAreaReducer from "./securityAreaReducer"
import positionReducer from "./positionReducer"
import permissionReducer from "./permissionReducer"
import rolePermissionReducer from "./rolePermissionReducer"
import employeeReducer from "./employeeReducer"
import exceptionReducer from "./exceptionReducer"

export default combineReducers({
    auth: authReducer,
    roles: roleReducer,
    securityAreas: securityAreaReducer,
    positions: positionReducer,
    permissions: permissionReducer,
    rolePermissions: rolePermissionReducer,
    employees: employeeReducer,
    exceptions: exceptionReducer
})
