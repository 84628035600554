import {
    CREATE_PERMISSION,
    DELETE_PERMISSION,
    GET_PERMISSIONS,
    UPDATE_PERMISSION
} from "./types"
import axios from "../auth/axiosAuth"
import { backendURL } from "../config"

export const getPermissions = () => async (dispatch) => {
    const res = await axios.get(`${backendURL}/permission`)
    dispatch({ type: GET_PERMISSIONS, payload: res.data })
}

export const createPermission = (newPermission) => async (dispatch) => {
    await axios.post(`${backendURL}/permission`, newPermission)
    dispatch({ type: CREATE_PERMISSION })
}

export const updatePermission = (updatedPermission) => async (dispatch) => {
    await axios.put(`${backendURL}/permission`, updatedPermission)
    dispatch({ type: UPDATE_PERMISSION })
}

export const deletePermission = (permissionToDelete) => async (dispatch) => {
    await axios.delete(`${backendURL}/permission`, {
        data: permissionToDelete
    })
    dispatch({ type: DELETE_PERMISSION })
}
