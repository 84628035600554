import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import App from "./components/main/App"
import ErrorBoundary from "./components/main/ErrorBoundary"
import { PersistGate } from "redux-persist/integration/react"
import { store, persistor } from "./store"

ReactDOM.render(
    <ErrorBoundary>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>
    </ErrorBoundary>,
    document.getElementById("root")
)
