import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
    Card,
    Typography,
    Tooltip,
    Grid,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    FormControl,
    InputLabel,
    Select,
    TextField
} from "@material-ui/core"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import {
    deletePosition,
    getPositions,
    updatePosition
} from "../../../actions/positionActions"

function Position(props) {
    const {
        positionID,
        roleID,
        department,
        jobTitle,
        classification,
        roleName,
        containsActiveEmployee
    } = props
    const dispatch = useDispatch()

    // Store Variables
    const roles = useSelector((state) => state.roles.roles)
    const user = useSelector((state) => state.auth.user.email)
    const userPermissions = useSelector((state) => state.auth.permissions)

    // Edit Position State
    const [editPositionDialogOpen, setEditPositionDialogOpen] = useState(false)
    const [editPositionDialogValues, setEditPositionDialogValues] = useState({
        department: "",
        jobTitle: "",
        classification: "",
        roleID: "none"
    })

    // Delete State
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [deleteDialogValues, setDeleteDialogValues] = useState({
        confirmDelete: ""
    })

    // Edit Position Handlers
    const handleClickEditPosition = () => {
        setEditPositionDialogValues({
            department,
            jobTitle,
            classification,
            roleID
        })
        setEditPositionDialogOpen(true)
    }

    const handleCloseEditPositionDialog = () => {
        setEditPositionDialogOpen(false)
        clearEditPositionDialogValues()
    }

    const clearEditPositionDialogValues = () => {
        setEditPositionDialogValues({
            department: "",
            jobTitle: "",
            classification: "",
            roleID: "none"
        })
    }

    const handleUpdatePosition = async () => {
        if (
            editPositionDialogValues.department !== "" &&
            editPositionDialogValues.jobTitle !== "" &&
            editPositionDialogValues.classification !== "" &&
            editPositionDialogValues.roleID !== "none"
        ) {
            const updatedPosition = {
                PositionID: positionID,
                RoleID: editPositionDialogValues.roleID,
                Department: editPositionDialogValues.department,
                JobTitle: editPositionDialogValues.jobTitle,
                Classification: editPositionDialogValues.classification,
                AuditLog: {
                    Module: "Access Management",
                    Action: "Update Position",
                    Object: `Position ID: ${positionID} | Department: ${editPositionDialogValues.department} | JobTitle: ${editPositionDialogValues.jobTitle} | Classification: ${editPositionDialogValues.classification} | RoleID: ${editPositionDialogValues.roleID}`,
                    ActionBy: user
                }
            }
            await dispatch(updatePosition(updatedPosition))
            await dispatch(getPositions())
            handleCloseEditPositionDialog()
            clearEditPositionDialogValues()
        }
    }

    const handleChangeEditPositionDialog = (e) => {
        const name = e.target.name
        const value = e.target.value

        setEditPositionDialogValues({
            ...editPositionDialogValues,
            [name]: value
        })
    }

    // Delete Handlers
    const handleClickDelete = () => {
        clearDeleteDialogValues()
        setDeleteDialogOpen(true)
    }

    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false)
        clearDeleteDialogValues()
    }

    const clearDeleteDialogValues = () => {
        setDeleteDialogValues({
            confirmDelete: ""
        })
    }

    const handleDelete = async () => {
        if (deleteDialogValues.confirmDelete === "confirm") {
            const positionToDelete = {
                PositionID: positionID,
                AuditLog: {
                    Module: "Access Management",
                    Action: "Delete Position",
                    Object: `Position ID: ${positionID} | Department: ${department} | JobTitle: ${jobTitle} | Classification: ${classification}`,
                    ActionBy: user
                }
            }
            await dispatch(deletePosition(positionToDelete))
            await dispatch(getPositions())
            handleCloseDeleteDialog()
            clearDeleteDialogValues()
        }
    }

    const handleChangeDeleteDialog = (e) => {
        const name = e.target.name
        const value = e.target.value

        setDeleteDialogValues({
            ...deleteDialogValues,
            [name]: value
        })
    }

    return (
        <Card>
            <Grid container>
                {/* Grid Left Side */}
                <Grid item xs={12} md={6}>
                    <Typography
                        variant="subtitle1"
                        style={{
                            marginLeft: "14px"
                        }}
                    >
                        {`${department} | ${classification}`}
                        <br />
                        {`${jobTitle}`}
                    </Typography>
                </Grid>
                {/* Grid Middle */}
                <Grid item align="right" xs={8} md={4}>
                    <Typography variant="body1" style={{ marginTop: "15px" }}>
                        {roleName}
                    </Typography>
                </Grid>
                {/* Grid Right Side */}
                <Grid item align="right" xs={4} md={2}>
                    {userPermissions.EditAccessManagement && (
                        <Tooltip title="Edit Position">
                            <IconButton
                                onClick={handleClickEditPosition}
                                style={{ marginTop: "4px" }}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    {userPermissions.EditAccessManagement && (
                        <Tooltip title="Delete Position">
                            <IconButton
                                onClick={handleClickDelete}
                                style={{ marginTop: "4px" }}
                                disabled={containsActiveEmployee}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </Grid>
            </Grid>

            {/* Edit Position Dialog */}
            <Dialog
                open={editPositionDialogOpen}
                onClose={handleCloseEditPositionDialog}
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle>Edit Position</DialogTitle>
                <DialogContent>
                    {/* Department Field */}
                    <TextField
                        margin="dense"
                        id="department"
                        label="Department"
                        type="text"
                        name="department"
                        value={editPositionDialogValues.department}
                        onChange={handleChangeEditPositionDialog}
                        fullWidth
                        disabled={containsActiveEmployee}
                    />
                    {/* Job Title Field */}
                    <TextField
                        margin="dense"
                        id="jobTitle"
                        label="Job Title"
                        type="text"
                        name="jobTitle"
                        value={editPositionDialogValues.jobTitle}
                        onChange={handleChangeEditPositionDialog}
                        fullWidth
                        disabled={containsActiveEmployee}
                    />
                    {/* Classification Field */}
                    <TextField
                        margin="dense"
                        id="classification"
                        label="Classification"
                        type="text"
                        name="classification"
                        value={editPositionDialogValues.classification}
                        onChange={handleChangeEditPositionDialog}
                        fullWidth
                        disabled={containsActiveEmployee}
                    />
                    {/* Role Field */}
                    <FormControl fullWidth={true} style={{ marginTop: "20px" }}>
                        <InputLabel htmlFor="securityAreaID">Role</InputLabel>
                        <Select
                            native
                            value={editPositionDialogValues.roleID}
                            onChange={handleChangeEditPositionDialog}
                            label="Role"
                            inputProps={{
                                name: "roleID",
                                id: "roleID"
                            }}
                        >
                            <option key="none" value="none">
                                Select a Role
                            </option>
                            {roles.map((role) => (
                                <option key={role.RoleID} value={role.RoleID}>
                                    {role.RoleName}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUpdatePosition} color="secondary">
                        Update
                    </Button>
                    <Button
                        onClick={handleCloseEditPositionDialog}
                        color="primary"
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete Dialog */}
            <Dialog
                open={deleteDialogOpen}
                onClose={handleCloseDeleteDialog}
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle>Delete Position</DialogTitle>
                <DialogContent>
                    <strong>WARNING: This action cannot be reversed!</strong>
                    <br /> Are you sure you want to delete this position?
                    {/* Confirm Delete Field */}
                    <TextField
                        margin="dense"
                        id="confirmDelete"
                        label="Enter 'confirm' to confirm"
                        type="text"
                        name="confirmDelete"
                        value={deleteDialogValues.confirmDelete}
                        onChange={handleChangeDeleteDialog}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDelete} color="secondary">
                        Delete
                    </Button>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Card>
    )
}

export default Position
