import {
    CLONE_ROLE_PERMISSIONS,
    GET_ROLE_PERMISSIONS,
    REPLACE_PERMISSION_ROLES,
    REPLACE_ROLE_PERMISSIONS
} from "../actions/types"

const initialState = {
    rolePermissions: []
}

const rolePermissionReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ROLE_PERMISSIONS:
            return {
                ...state,
                rolePermissions: action.payload
            }
        case REPLACE_ROLE_PERMISSIONS:
            return {
                ...state
            }
        case REPLACE_PERMISSION_ROLES:
            return {
                ...state
            }
        case CLONE_ROLE_PERMISSIONS:
            return {
                ...state
            }
        default:
            return state
    }
}

export default rolePermissionReducer
