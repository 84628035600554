import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
    Card,
    Typography,
    IconButton,
    Tooltip,
    Grid,
    Collapse,
    CardContent,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Link,
    CardHeader,
    Checkbox,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Switch
} from "@material-ui/core"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import GroupAddIcon from "@mui/icons-material/GroupAdd"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { ExpandMore } from "../../main/ExpandMore"
import {
    deletePermission,
    getPermissions,
    updatePermission
} from "../../../actions/permissionActions"
import {
    getRolePermissions,
    replacePermissionRoles
} from "../../../actions/rolePermissionActions"

// Transfer List Functions
function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1)
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1)
}

function union(a, b) {
    return [...a, ...not(b, a)]
}

function Permission(props) {
    const {
        permissionID,
        category,
        application,
        module,
        permissionLevel,
        documentationURL,
        exclusive,
        restriction,
        costAssociated
    } = props
    const dispatch = useDispatch()

    // Store Variables
    const rolePermissions = useSelector(
        (state) => state.rolePermissions.rolePermissions
    )
    const roles = useSelector((state) => state.roles.roles)
    const user = useSelector((state) => state.auth.user.email)
    const userPermissions = useSelector((state) => state.auth.permissions)

    // State Variables
    const [expanded, setExpanded] = useState(false)

    // Edit Dialog State
    const [editDialogOpen, setEditDialogOpen] = useState(false)
    const [editDialogValues, setEditDialogValues] = useState({
        category: "",
        application: "",
        module: "",
        permissionLevel: "",
        documentationURL: "",
        costAssociated: ""
    })

    const [costAssociatedValue, setCostAssociatedValue] = useState(false)

    // Delete Dialog State
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [deleteDialogValues, setDeleteDialogValues] = useState({
        confirmDelete: ""
    })

    // Edit Role Permissions Dialog State
    const [editPermissionRolesDialogOpen, setEditPermissionRolesDialogOpen] =
        useState(false)

    // Transfer List State and Variables
    const [checked, setChecked] = useState([])
    const [left, setLeft] = useState([])
    const [right, setRight] = useState([])

    const leftChecked = intersection(checked, left)
    const rightChecked = intersection(checked, right)
    const numberOfChecked = (items) => intersection(checked, items).length

    // Handlers
    const handleClickExpand = () => {
        setExpanded(!expanded)
    }

    // Edit Dialog Handlers
    const handleClickEdit = () => {
        setEditDialogValues({
            category,
            application,
            module,
            permissionLevel,
            documentationURL
        })
        setCostAssociatedValue(costAssociated)
        setEditDialogOpen(true)
    }

    const handleCloseEditDialog = () => {
        setEditDialogOpen(false)
        clearEditDialogValues()
    }

    const handleChangeEditDialog = (e) => {
        const name = e.target.name
        const value = e.target.value

        setEditDialogValues({
            ...editDialogValues,
            [name]: value
        })
    }

    const handleChangeCostAssociated = (e) => {
        setCostAssociatedValue(e.target.checked)
    }

    const clearEditDialogValues = () => {
        setEditDialogValues({
            category: "",
            application: "",
            module: "",
            permissionLevel: "",
            documentationURL: ""
        })
        setCostAssociatedValue(false)
    }

    const handleUpdatePermission = async () => {
        const updatedPermission = {
            PermissionID: permissionID,
            Category: editDialogValues.category,
            Application: editDialogValues.application,
            Module: editDialogValues.module,
            PermissionLevel: editDialogValues.permissionLevel,
            DocumentationURL: editDialogValues.documentationURL,
            Exclusive: exclusive,
            Restriction: restriction,
            CostAssociated: costAssociatedValue,
            AuditLog: {
                Module: "Access Management",
                Action: "Update Permission",
                Object: `Permission ID: ${permissionID} | Catgory: ${editDialogValues.category} | Application: ${editDialogValues.application} | Module: ${editDialogValues.module} | Permission Level: ${editDialogValues.permissionLevel} | DocumentationURL: ${editDialogValues.documentationURL} | Cost Associated: ${costAssociatedValue}`,
                ActionBy: user
            }
        }
        await dispatch(updatePermission(updatedPermission))
        await dispatch(getPermissions())
        handleCloseEditDialog()
        clearEditDialogValues()
    }

    // Delete Dialog Handlers
    const handleClickDelete = () => {
        clearDeleteDialogValues()
        setDeleteDialogOpen(true)
    }

    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false)
        clearDeleteDialogValues()
    }

    const clearDeleteDialogValues = () => {
        setDeleteDialogValues({
            confirmDelete: ""
        })
    }

    const handleDeletePermission = async () => {
        if (deleteDialogValues.confirmDelete === "confirm") {
            const permissionToDelete = {
                PermissionID: permissionID,
                AuditLog: {
                    Module: "Access Management",
                    Action: "Delete Permission",
                    Object: `Permission ID: ${permissionID} | Catgory: ${category} | Application: ${application} | Module: ${module} | Permission Level: ${permissionLevel}`,
                    ActionBy: user
                }
            }
            await dispatch(deletePermission(permissionToDelete))
            await dispatch(getPermissions())
            handleCloseDeleteDialog()
            clearDeleteDialogValues()
        }
    }

    const handleChangeDeleteDialog = (e) => {
        const name = e.target.name
        const value = e.target.value

        setDeleteDialogValues({
            ...deleteDialogValues,
            [name]: value
        })
    }

    // Edit Permission Roles Dialog Handlers
    const handleClickEditPermissionRoles = () => {
        const includedRoles = rolePermissions.filter(
            (role) => role.PermissionID === permissionID
        )
        setLeft(
            roles.filter(
                (role) =>
                    !includedRoles.some(
                        (includedRole) => includedRole.RoleID === role.RoleID
                    )
            )
        )
        setRight(includedRoles)
        setEditPermissionRolesDialogOpen(true)
    }

    const handleCloseEditPermissionRolesDialog = () => {
        setEditPermissionRolesDialogOpen(false)
        setLeft([])
        setRight([])
    }

    const handleUpdatePermissionRoles = async () => {
        if (right.length > 0) {
            const newRoles = right.map((role) => role.RoleID)
            const replacementObject = {
                PermissionID: permissionID,
                RoleIDs: newRoles.join(","),
                AuditLog: {
                    Module: "Access Management",
                    Action: "Update Permission Roles",
                    Object: `Permission ID: ${permissionID} | Catgory: ${category} | Application: ${application} | Module: ${module} | Permission Level: ${permissionLevel} | Role IDs: ${newRoles.join(
                        ","
                    )}`,
                    ActionBy: user
                }
            }
            console.log(replacementObject)
            await dispatch(replacePermissionRoles(replacementObject))
            await dispatch(getRolePermissions())
            handleCloseEditPermissionRolesDialog()
        }
    }

    // Transfer List Handlers
    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value)
        const newChecked = [...checked]

        if (currentIndex === -1) {
            newChecked.push(value)
        } else {
            newChecked.splice(currentIndex, 1)
        }

        setChecked(newChecked)
    }

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items))
        } else {
            setChecked(union(checked, items))
        }
    }

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked))
        setLeft(not(left, leftChecked))
        setChecked(not(checked, leftChecked))
    }

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked))
        setRight(not(right, rightChecked))
        setChecked(not(checked, rightChecked))
    }

    // Transfer List Component
    const customList = (title, items) => (
        <Card>
            <CardHeader
                sx={{ px: 2, py: 1 }}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={
                            numberOfChecked(items) === items.length &&
                            items.length !== 0
                        }
                        indeterminate={
                            numberOfChecked(items) !== items.length &&
                            numberOfChecked(items) !== 0
                        }
                        disabled={items.length === 0}
                        inputProps={{
                            "aria-label": "all items selected"
                        }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selected`}
            />
            <Divider />
            <List
                sx={{
                    width: 200,
                    height: 230,
                    bgcolor: "background.paper",
                    overflow: "auto"
                }}
                dense
                component="div"
                role="list"
            >
                {items.map((role) => {
                    const labelId = `transfer-list-all-item-${role.RoleID}-label`

                    return (
                        <ListItem
                            key={role.RoleID}
                            role="listitem"
                            button
                            onClick={handleToggle(role)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(role) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        "aria-labelledby": labelId
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                id={labelId}
                                primary={`${role.RoleName}`}
                            />
                        </ListItem>
                    )
                })}
            </List>
        </Card>
    )

    return (
        <Card>
            <Grid container>
                {/* Grid Left Side */}
                <Grid item xs={12} md={9}>
                    <Typography
                        variant="subtitle1"
                        style={{
                            marginTop: "9px",
                            marginLeft: "14px"
                        }}
                    >
                        {`${category} | ${application} | ${module} | ${permissionLevel}`}
                    </Typography>
                </Grid>
                {/* Grid Right Side */}
                <Grid item align="right" xs={12} md={3}>
                    {userPermissions.EditAccessManagement && (
                        <Tooltip title="Edit Permission">
                            <IconButton onClick={handleClickEdit}>
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    {userPermissions.EditAccessManagement && (
                        <Tooltip title="Edit Permission Roles">
                            <IconButton
                                onClick={handleClickEditPermissionRoles}
                            >
                                <GroupAddIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    {userPermissions.EditAccessManagement && (
                        <Tooltip title="Delete Permission">
                            <IconButton onClick={handleClickDelete}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    <ExpandMore
                        expand={expanded}
                        onClick={handleClickExpand}
                        aria-expanded={expanded}
                    >
                        <Tooltip title={!expanded ? "Show More" : "Show Less"}>
                            <ExpandMoreIcon />
                        </Tooltip>
                    </ExpandMore>
                </Grid>
            </Grid>

            {/* Expand More Content */}
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Typography variant="subtitle2" gutterBottom>
                        {costAssociated
                            ? "$$ Cost Associated $$"
                            : "No Cost Associated"}
                    </Typography>
                    {documentationURL && (
                        <Typography variant="subtitle2" gutterBottom>
                            <Link
                                href={documentationURL}
                                target="_blank"
                                rel="noopener"
                                style={{ color: "#DF7A00" }}
                            >
                                Link to Documentation
                            </Link>
                        </Typography>
                    )}
                    <Typography variant="subtitle2">Included Roles:</Typography>
                    {rolePermissions
                        .filter(
                            (permission) =>
                                permission.PermissionID === permissionID
                        )
                        .map((permission) => (
                            <Typography variant="body2">
                                {`${permission.RoleName}`}
                                {/* add more info here */}
                            </Typography>
                        ))}
                </CardContent>
            </Collapse>

            {/* Edit Dialog */}
            <Dialog
                open={editDialogOpen}
                onClose={handleCloseEditDialog}
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle>Edit Permission</DialogTitle>
                <DialogContent>
                    {/* Documentation URL Field */}
                    <TextField
                        autoFocus
                        margin="dense"
                        id="documentationURL"
                        label="Documentation URL"
                        type="text"
                        name="documentationURL"
                        value={editDialogValues.documentationURL}
                        onChange={handleChangeEditDialog}
                        fullWidth
                    />
                    {/* Cost Associated Field */}
                    Cost Associated
                    <Switch
                        checked={costAssociatedValue}
                        onChange={handleChangeCostAssociated}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUpdatePermission} color="secondary">
                        Update
                    </Button>
                    <Button onClick={handleCloseEditDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete Dialog */}
            <Dialog
                open={deleteDialogOpen}
                onClose={handleCloseDeleteDialog}
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle>Delete Permission</DialogTitle>
                <DialogContent>
                    <strong>WARNING: This action cannot be reversed!</strong>
                    <br /> Are you sure you want to delete this permission?
                    {/* Confirm Delete Field */}
                    <TextField
                        margin="dense"
                        id="confirmDelete"
                        label="Enter 'confirm' to confirm"
                        type="text"
                        name="confirmDelete"
                        value={deleteDialogValues.confirmDelete}
                        onChange={handleChangeDeleteDialog}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeletePermission} color="secondary">
                        Delete
                    </Button>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Edit Permission Roles Dialog */}
            <Dialog
                open={editPermissionRolesDialogOpen}
                onClose={handleCloseEditPermissionRolesDialog}
                fullWidth={true}
                maxWidth="xl"
                style={{ marginTop: "5vh" }}
            >
                <DialogTitle>Edit Permission Roles</DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle1">{`Editing roles for: ${category} | ${application} | ${module} | ${permissionLevel}`}</Typography>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} md={5}>
                            {customList("Available Roles", left)}
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <Grid
                                container
                                direction="column"
                                alignItems="center"
                            >
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleCheckedRight}
                                    disabled={leftChecked.length === 0}
                                    aria-label="move selected right"
                                >
                                    &gt;
                                </Button>
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleCheckedLeft}
                                    disabled={rightChecked.length === 0}
                                    aria-label="move selected left"
                                >
                                    &lt;
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            {customList("Assigned Roles", right)}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleUpdatePermissionRoles}
                        color="secondary"
                    >
                        Update
                    </Button>
                    <Button
                        onClick={handleCloseEditPermissionRolesDialog}
                        color="primary"
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Card>
    )
}

export default Permission
