export const GET_USER_DATA = "GET_USER_DATA"
export const TOGGLE_DARKMODE = "TOGGLE_DARKMODE"
export const GET_AUTHORIZATION_FOR_APPLICATION =
    "GET_AUTHORIZATION_FOR_APPLICATION"
export const CREATE_PENDING_USER = "CREATE_PENDING_USER"
export const CREATE_AUDIT_TRAIL = "CREATE_AUDIT_TRAIL"
export const LOGOUT_USER = "LOGOUT_USER"
export const LOGIN_USER = "LOGIN_USER"

export const GET_ACTIVE_EMPLOYEES = "GET_ACTIVE_EMPLOYEES"

export const GET_ROLES = "GET_ROLES"
export const CREATE_ROLE = "CREATE_ROLE"
export const UPDATE_ROLE = "UPDATE_ROLE"
export const DELETE_ROLE = "DELETE_ROLE"
export const UPDATE_ROLE_FILTER = "UPDATE_ROLE_FILTER"

export const GET_SECURITY_AREAS = "GET_SECURITY_AREAS"
export const CREATE_SECURITY_AREA = "CREATE_SECURITY_AREA"
export const UPDATE_SECURITY_AREA = "UPDATE_SECURITY_AREA"
export const DELETE_SECURITY_AREA = "DELETE_SECURITY_AREA"

export const GET_POSITIONS = "GET_POSITIONS"
export const GET_ADP_POSITIONS_DELTA = "GET_ADP_POSITIONS_DELTA"
export const CREATE_POSITION = "CREATE_POSITION"
export const UPDATE_POSITION = "UPDATE_POSITION"
export const DELETE_POSITION = "DELETE_POSITION"

export const GET_PERMISSIONS = "GET_PERMISSIONS"
export const CREATE_PERMISSION = "CREATE_PERMISSION"
export const UPDATE_PERMISSION = "UPDATE_PERMISSION"
export const DELETE_PERMISSION = "DELETE_PERMISSION"

export const GET_ROLE_PERMISSIONS = "GET_ROLE_PERMISSIONS"
export const REPLACE_ROLE_PERMISSIONS = "REPLACE_ROLE_PERMISSIONS"
export const REPLACE_PERMISSION_ROLES = "REPLACE_PERMISSION_ROLES"
export const CLONE_ROLE_PERMISSIONS = "CLONE_ROLE_PERMISSIONS"

export const GET_EXCEPTIONS = "GET_EXCEPTIONS"
export const CREATE_EXCEPTION = "CREATE_EXCEPTION"
export const UPDATE_EXCEPTION = "UPDATE_EXCEPTION"
export const DELETE_EXCEPTION = "DELETE_EXCEPTION"
