import { useEffect } from "react"
import "firebase/auth"
import firebaseAuth from "../auth/firebaseAuth"
import { loginUser, logoutUser } from "../actions/authActions"

export function useAuth(dispatch) {
    useEffect(() => {
        function runLoginFunction(user) {
            if (user) {
                dispatch(loginUser(user))
            } else {
                dispatch(logoutUser())
            }
        }

        const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
            runLoginFunction(user)
        })

        return () => {
            unsubscribe()
        }
    }, [dispatch])
}
