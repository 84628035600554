import {
    CREATE_ROLE,
    DELETE_ROLE,
    GET_ROLES,
    UPDATE_ROLE,
    UPDATE_ROLE_FILTER
} from "../actions/types"

const initialState = {
    roles: [],
    roleFilter: "none"
}

const roleReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ROLES:
            return {
                ...state,
                roles: action.payload
            }
        case CREATE_ROLE:
            return {
                ...state
            }
        case UPDATE_ROLE:
            return {
                ...state
            }
        case DELETE_ROLE:
            return {
                ...state
            }
        case UPDATE_ROLE_FILTER:
            return {
                ...state,
                roleFilter: action.payload
            }
        default:
            return state
    }
}

export default roleReducer
