import React from "react"
import {
    Card,
    CardContent,
    Grid,
    IconButton,
    Tooltip,
    Typography
} from "@material-ui/core"
import { useNavigate } from "react-router-dom"
import PeopleAltIcon from "@mui/icons-material/PeopleAlt"
import LockIcon from "@mui/icons-material/Lock"

function AccessManagementLinks() {
    const navigate = useNavigate()

    const handleClickRoles = () => {
        navigate("/roles")
    }

    const handleClickPermissions = () => {
        navigate("/permissions")
    }

    return (
        <Grid item xs={12}>
            <Grid container align="center">
                <Grid item xs={6} align="right">
                    <Card
                        align="center"
                        style={{
                            maxWidth: "200px",
                            marginLeft: "10px",
                            marginRight: "10px"
                        }}
                    >
                        <CardContent>
                            <Tooltip title="Roles">
                                <IconButton onClick={handleClickRoles}>
                                    <PeopleAltIcon />
                                </IconButton>
                            </Tooltip>
                            <Typography variant="subtitle1">Roles</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6} align="left">
                    <Card
                        align="center"
                        style={{
                            maxWidth: "200px",
                            marginLeft: "10px",
                            marginRight: "10px"
                        }}
                    >
                        <CardContent>
                            <Tooltip title="Permissions">
                                <IconButton onClick={handleClickPermissions}>
                                    <LockIcon />
                                </IconButton>
                            </Tooltip>
                            <Typography variant="subtitle1">
                                Permissions
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default AccessManagementLinks
