import React from "react"
import { useSelector } from "react-redux"
import { Grid, Typography } from "@material-ui/core"
import Stack from "@mui/material/Stack"
import Skeleton from "@mui/material/Skeleton"
import Role from "./Role"

function RolesPageMain() {
    // Store Variables
    const roles = useSelector((state) => state.roles.roles)
    const roleFilter = useSelector((state) => state.roles.roleFilter)

    // Constants
    const arrayOfFifteen = Array.from({ length: 15 }, (_, index) => index + 1)

    return (
        <Grid container justifyContent="center">
            <Stack spacing={1} style={{ width: "80%", marginBottom: "20px" }}>
                <Typography variant="h5">Roles</Typography>
                {roles?.length > 0
                    ? roles
                          .filter(
                              (role) =>
                                  roleFilter === "none" ||
                                  role.SecurityAreaID === roleFilter
                          )
                          .map((role) => (
                              <Role
                                  roleID={role.RoleID}
                                  roleName={role.RoleName}
                                  roleShortName={role.RoleShortName}
                                  securityAreaID={role.SecurityAreaID}
                                  securityAreaName={role.SecurityAreaName}
                              />
                          ))
                    : arrayOfFifteen.map((index) => (
                          <Skeleton
                              variant="rounded"
                              height={50}
                              animation="wave"
                          />
                      ))}
            </Stack>
        </Grid>
    )
}

export default RolesPageMain
