import {
    CLONE_ROLE_PERMISSIONS,
    GET_ROLE_PERMISSIONS,
    REPLACE_PERMISSION_ROLES,
    REPLACE_ROLE_PERMISSIONS
} from "./types"
import axios from "../auth/axiosAuth"
import { backendURL } from "../config"

export const getRolePermissions = () => async (dispatch) => {
    const res = await axios.get(`${backendURL}/role-permission`)
    dispatch({ type: GET_ROLE_PERMISSIONS, payload: res.data })
}

export const replaceRolePermissions =
    (replacementObject) => async (dispatch) => {
        await axios.post(
            `${backendURL}/role-permission/replace`,
            replacementObject
        )
        dispatch({ type: REPLACE_ROLE_PERMISSIONS })
    }

export const replacePermissionRoles =
    (replacementObject) => async (dispatch) => {
        await axios.post(
            `${backendURL}/role-permission/replace/permission`,
            replacementObject
        )
        dispatch({ type: REPLACE_PERMISSION_ROLES })
    }

export const cloneRolePermissions = (roleToClone) => async (dispatch) => {
    await axios.post(`${backendURL}/role-permission/clone`, roleToClone)
    dispatch({ type: CLONE_ROLE_PERMISSIONS })
}
