import React from "react"
import { Link } from "react-router-dom"
import { Grid, Typography } from "@material-ui/core"
import useStyles from "./styles/LoginPromptStyles"

function LoginPrompt() {
    const classes = useStyles()

    return (
        <Grid container justify="center">
            <Typography variant="h4">
                Please{" "}
                <Link to={"/"} className={classes.LoginLink}>
                    Login
                </Link>{" "}
                to view this page
            </Typography>
        </Grid>
    )
}

export default LoginPrompt
