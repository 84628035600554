import "firebase/compat/auth"
import firebase from "firebase/compat/app"
import { firebaseConfig } from "../config"

firebase.initializeApp(firebaseConfig)

const firebaseAuth = firebase.auth()

firebaseAuth.setPersistence(firebase.auth.Auth.Persistence.SESSION)

export default firebaseAuth
