import {
    CREATE_EXCEPTION,
    DELETE_EXCEPTION,
    GET_EXCEPTIONS,
    UPDATE_EXCEPTION
} from "./types"
import axios from "../auth/axiosAuth"
import { backendURL, appAdminBackendURL } from "../config"

export const getExceptions = () => async (dispatch) => {
    const exceptions = await axios.get(`${backendURL}/exception`)
    const permissions = await axios.get(`${backendURL}/permission`)
    const employees = await axios.get(`${appAdminBackendURL}/employee`)

    const exceptionsData = exceptions.data.map((exception) => {
        const matchPermission = permissions.data.find(
            (permission) => permission.PermissionID === exception.PermissionID
        )
        const matchEmployee = employees.data.find(
            (employee) => employee.AssociateOID === exception.AssociateOID
        )
        return {
            ...exception,
            FullName: `${matchEmployee.FirstName} ${matchEmployee.LastName}`,
            Permission: `${matchPermission.Category} | ${matchPermission.Application} | ${matchPermission.Module} | ${matchPermission.PermissionLevel}`
        }
    })

    dispatch({ type: GET_EXCEPTIONS, payload: exceptionsData })
}

export const createException = (newException) => async (dispatch) => {
    await axios.post(`${backendURL}/exception`, newException)
    dispatch({ type: CREATE_EXCEPTION })
}

export const updateException = (updatedException) => async (dispatch) => {
    await axios.put(`${backendURL}/exception`, updatedException)
    dispatch({ type: UPDATE_EXCEPTION })
}

export const deleteException = (exceptionToDelete) => async (dispatch) => {
    await axios.delete(`${backendURL}/exception`, {
        data: exceptionToDelete
    })
    dispatch({ type: DELETE_EXCEPTION })
}
