import {
    GET_ROLES,
    CREATE_ROLE,
    UPDATE_ROLE_FILTER,
    UPDATE_ROLE,
    DELETE_ROLE
} from "./types"
import axios from "../auth/axiosAuth"
import { backendURL } from "../config"

export const getRoles = () => async (dispatch) => {
    const res = await axios.get(`${backendURL}/role`)
    dispatch({ type: GET_ROLES, payload: res.data })
}

export const createRole = (newRole) => async (dispatch) => {
    await axios.post(`${backendURL}/role`, newRole)
    dispatch({ type: CREATE_ROLE })
}

export const updateRole = (updatedRole) => async (dispatch) => {
    await axios.put(`${backendURL}/role`, updatedRole)
    dispatch({ type: UPDATE_ROLE })
}

export const deleteRole = (roleToDelete) => async (dispatch) => {
    await axios.delete(`${backendURL}/role`, {
        data: roleToDelete
    })
    dispatch({ type: DELETE_ROLE })
}

export const updateRoleFilter = (securityAreaID) => (dispatch) => {
    dispatch({ type: UPDATE_ROLE_FILTER, payload: securityAreaID })
}
