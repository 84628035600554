import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
    LoginLink: {
        textDecoration: "none",
        color: "#DF7A00"
    }
}))

export default useStyles
