import {
    GET_AUTHORIZATION_FOR_APPLICATION,
    GET_USER_DATA,
    TOGGLE_DARKMODE,
    CREATE_AUDIT_TRAIL,
    CREATE_PENDING_USER,
    LOGOUT_USER,
    LOGIN_USER
} from "../actions/types"

const initialState = {
    authenticated: false,
    userData: {},
    permissions: {
        ViewAccessManagement: false,
        EditAccessManagement: false
    },
    user: null
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_AUTHORIZATION_FOR_APPLICATION:
            let newPermissions = { ...state.permissions }
            action.payload.permissions.forEach((authorization) => {
                const permission = authorization.Permission.split(" ").join("")
                switch (permission) {
                    case permission:
                        newPermissions = {
                            ...newPermissions,
                            [permission]: true
                        }
                        break
                    default:
                        newPermissions = { ...newPermissions }
                }
            })
            return {
                ...state,
                authenticated: action.payload.authenticated,
                permissions: newPermissions
            }
        case GET_USER_DATA:
            return {
                ...state,
                userData: action.payload
            }
        case TOGGLE_DARKMODE:
            return { ...state }
        case CREATE_AUDIT_TRAIL:
            return { ...state }
        case CREATE_PENDING_USER:
            return { ...state }
        case LOGIN_USER:
            return {
                ...state,
                user: action.payload
            }
        case LOGOUT_USER:
            return {
                ...initialState
            }
        default:
            return state
    }
}

export default authReducer
