import React from "react"
import { Grid, Typography, Button } from "@material-ui/core"
import useStyles from "./styles/UserNotFoundStyles"

function UserNotFound() {
    const classes = useStyles()

    const handleContactGDS = () => {
        const mailto = "servicedesk@precipart.com"
        const subject = `New Application User Setup`
        const body = `Request for user setup sent from Request Account Setup feature.`
        window.open(`mailto:${mailto}?subject=${subject}&body=${body}`)
    }

    return (
        <Grid container justify="center">
            <Grid item align="center" xs={10}>
                <Typography variant="h5">
                    Please contact the Global Digital Solutions team for help
                    setting up your account
                </Typography>
            </Grid>
            <Grid item align="center" xs={10} className={classes.EmailButton}>
                <Button variant="outlined" onClick={handleContactGDS}>
                    Send An Email
                </Button>
            </Grid>
        </Grid>
    )
}

export default UserNotFound
