import React, { useState } from "react"
import clsx from "clsx"
import { useSelector, useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import {
    AppBar,
    Toolbar,
    Typography,
    Drawer,
    List,
    IconButton,
    ListItem,
    ListItemText,
    ListItemIcon,
    Divider,
    Tooltip,
    Chip
} from "@material-ui/core"
import MenuIcon from "@mui/icons-material/Menu"
import Brightness3Icon from "@mui/icons-material/Brightness3"
import DashboardIcon from "@mui/icons-material/Dashboard"
import WbSunnyIcon from "@mui/icons-material/WbSunny"
import HomeIcon from "@mui/icons-material/Home"
import LogoutIcon from "@mui/icons-material/Logout"
import PeopleAltIcon from "@mui/icons-material/PeopleAlt"
import LockIcon from "@mui/icons-material/Lock"
import NewReleasesIcon from "@mui/icons-material/NewReleases"
import firebaseAuth from "../../auth/firebaseAuth"
import logo from "../../images/precipartLogoSquare.png"
import useStyles from "./styles/NavigationStyles"
import { homeURL, environment } from "../../config"
import {
    getUserData,
    logoutUser,
    toggleDarkMode
} from "../../actions/authActions"

function Navigation() {
    const classes = useStyles()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const userData = useSelector((state) => state.auth.userData)

    const [open, setOpen] = useState(false)

    const navigationItems = [
        {
            text: "Home",
            path: "/home",
            visible: true,
            icon: <DashboardIcon />
        },
        {
            text: "Roles",
            path: "/roles",
            visible: true,
            icon: <PeopleAltIcon />
        },
        {
            text: "Permissions",
            path: "/permissions",
            visible: true,
            icon: <LockIcon />
        }
    ]

    const handleDarkModeToggle = async () => {
        await dispatch(toggleDarkMode(userData))
        await dispatch(getUserData(userData.GoogleID))
    }

    const handleDrawerOpen = () => {
        setOpen(true)
    }

    const handleDrawerClose = () => {
        setOpen(false)
    }

    const handleGoHome = async () => {
        setOpen(false)
        window.open(homeURL, "_self")
    }

    const handleLogout = async () => {
        handleDrawerClose()
        dispatch(logoutUser())
        await firebaseAuth.signOut()
        navigate("/")
    }

    return (
        <div className={classes.Navigation}>
            <AppBar
                position="fixed"
                className={clsx(classes.AppBar, {
                    [classes.AppBarShift]: open
                })}
                PaperProps={{ elevation: 24 }}
            >
                <Toolbar>
                    <img
                        alt="Precipart Logo"
                        src={logo}
                        className={classes.Logo}
                    />
                    <Typography
                        variant="h6"
                        className={classes.ApplicationName}
                        noWrap
                    >
                        Access Management
                        {environment && environment !== "production" && (
                            <Chip
                                icon={<NewReleasesIcon />}
                                label={environment.toUpperCase()}
                                style={{ marginLeft: "5px" }}
                            />
                        )}
                    </Typography>
                    {userData.AssociateOID && (
                        <Tooltip title={open ? "Close Menu" : "Open Menu"}>
                            <IconButton
                                color="inherit"
                                aria-label={
                                    open ? "close drawer" : "open drawer"
                                }
                                edge="end"
                                onClick={
                                    open ? handleDrawerClose : handleDrawerOpen
                                }
                            >
                                <MenuIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </Toolbar>
            </AppBar>

            <div
                className={clsx(classes.Content, {
                    [classes.contentShift]: open
                })}
            >
                <div className={classes.DrawerHeader} />
            </div>
            <Drawer
                className={classes.Drawer}
                variant="persistent"
                anchor="right"
                open={open}
                PaperProps={{ elevation: 15 }}
            >
                <div className={classes.DrawerHeader} />
                <div className={classes.DrawerHeader}>
                    <Tooltip title="Return Home">
                        <IconButton>
                            <HomeIcon onClick={handleGoHome} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Logout">
                        <IconButton>
                            <LogoutIcon onClick={handleLogout} />
                        </IconButton>
                    </Tooltip>
                    {userData && (
                        <Tooltip title="Toggle Darkmode">
                            <IconButton onClick={handleDarkModeToggle}>
                                {userData.DarkMode === "dark" ? (
                                    <WbSunnyIcon />
                                ) : (
                                    <Brightness3Icon />
                                )}
                            </IconButton>
                        </Tooltip>
                    )}
                </div>
                <Divider />
                <List>
                    {navigationItems
                        .filter((item) => item.visible)
                        .map((item) => (
                            <ListItem
                                button
                                key={item.text}
                                component={Link}
                                to={item.path}
                                onClick={handleDrawerClose}
                            >
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText primary={item.text} />
                            </ListItem>
                        ))}
                </List>
            </Drawer>
        </div>
    )
}

export default Navigation
