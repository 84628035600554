import {
    CREATE_EXCEPTION,
    DELETE_EXCEPTION,
    GET_EXCEPTIONS,
    UPDATE_EXCEPTION
} from "../actions/types"

const initialState = {
    exceptions: []
}

const exceptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_EXCEPTIONS:
            return {
                ...state,
                exceptions: action.payload
            }
        case CREATE_EXCEPTION:
            return {
                ...state
            }
        case UPDATE_EXCEPTION:
            return {
                ...state
            }
        case DELETE_EXCEPTION:
            return {
                ...state
            }
        default:
            return state
    }
}

export default exceptionReducer
