import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
    Footer: {
        marginTop: "auto",
        backgroundColor: "#d1d4d3",
        color: "black"
    },

    FooterItems: {
        display: "flex",
        justifyContent: "space-between"
    }
}))

export default useStyles
